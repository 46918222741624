import API_BASE_URL from "../config/api";

export const cardPaymentService = {
    cardPaymentMethod,
    banktransferYappyAndClavePaymentMethod
};

function cardPaymentMethod(customerId, productsArr, isGuestCheckoutSubmited, billingInfoObj, shippingInfoObj, deliveryInfoObj, cardInfoObj, paymentMethod, couponInfoObj, clientIpAddress, bacDuration = null) {
    let customer_id = customerId;
    let product_arr = productsArr;
    let first_name = billingInfoObj.billingFirstName;
    let last_name = billingInfoObj.billingLastName;
    let address_1 = billingInfoObj.billingAddress1;
    let address_2 = billingInfoObj.billingAddress2;
    let country_id = billingInfoObj.billingCountry;
    let city_id = billingInfoObj.billingCity;
    let state_id = billingInfoObj.billingState;
    let telephone = billingInfoObj.billingPhone;

    let isShippingSameAsBilling = shippingInfoObj.isShippingSameAsBilling;
    var address_ship = 0;
    if(isShippingSameAsBilling){
        address_ship = 1;
    }
    let shipping_address = shippingInfoObj.shippingAddress1;
    let city_ship = shippingInfoObj.shippingCity;
    let state_ship = shippingInfoObj.shippingState;
    let country_ship = shippingInfoObj.shippingCountry;
    let telephone_ship = shippingInfoObj.shippingPhone;

    let req_delivery_type = deliveryInfoObj.deliveryType;
    var delivery_type = "";
    if(req_delivery_type==="1"){
        delivery_type = "pickup";
    } else if(req_delivery_type==="2") {
        delivery_type = "delivery";
    }
    let pickup_id = deliveryInfoObj.pickupStoreId;
    let zone = deliveryInfoObj.zoneId;
    if(zone==="Zone A"){
        zone = "A";
    }
    let delivery_cost = deliveryInfoObj.deliveryCost;

    let payment_method = paymentMethod;
    let bac_duration = bacDuration;
    let ccnumber = cardInfoObj.cardNumber;

    let discount_type = couponInfoObj.discountType;
    let discount_amount = couponInfoObj.discountAmount;
    let discount_for = couponInfoObj.discountFor;
    let product_ids = couponInfoObj.cartProductIds;

    //let cart_selected_attributes = cartAttributesObj;
    let client_ip_address = clientIpAddress;
    let requestApiUrl;
    let requestOptions;

    if(!isGuestCheckoutSubmited){
        //requestApiUrl = 'https://www.evisionstore.com/api/checkout/placeorder-react-beta.php';
        requestApiUrl = `${API_BASE_URL}/place-order`;
        requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify({  
                customer_id, first_name, last_name, address_1, address_2, country_id, city_id, state_id, telephone, address_ship, shipping_address,
                city_ship, state_ship, country_ship, telephone_ship, delivery_type, pickup_id, zone, delivery_cost, payment_method, ccnumber, discount_type, 
                discount_amount, discount_for, product_ids, client_ip_address, bac_duration
            })
        };
    }else{
        let email_id = billingInfoObj.billingEmail;
        let order_placed_from = "website";
        //requestApiUrl = 'https://www.evisionstore.com/api/checkout/placeorder-react-with-guest.php';
        requestApiUrl = `${API_BASE_URL}/place-order`;
        requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify({  
                product_arr, email_id, first_name, last_name, address_1, address_2, country_id, city_id, state_id, telephone, address_ship, shipping_address,
                city_ship, state_ship, country_ship, telephone_ship, delivery_type, pickup_id, zone, delivery_cost, payment_method, ccnumber, discount_type, 
                discount_amount, discount_for, client_ip_address, order_placed_from, bac_duration
            })
        };
    }

    return fetch(requestApiUrl, requestOptions)
    .then(handleCardPaymentResponse)
    .then(res => {
        return res;
    });
}

function banktransferYappyAndClavePaymentMethod(customerId, productsArr, isGuestCheckoutSubmited, billingInfoObj, shippingInfoObj, deliveryInfoObj, paymentMethod, couponInfoObj, clientIpAddress) {
    let customer_id = customerId;
    let product_arr = productsArr;
    let first_name = billingInfoObj.billingFirstName;
    let last_name = billingInfoObj.billingLastName;
    let address_1 = billingInfoObj.billingAddress1;
    let address_2 = billingInfoObj.billingAddress2;
    let country_id = billingInfoObj.billingCountry;
    let city_id = billingInfoObj.billingCity;
    let state_id = billingInfoObj.billingState;
    let telephone = billingInfoObj.billingPhone;

    let isShippingSameAsBilling = shippingInfoObj.isShippingSameAsBilling;
    var address_ship = 0;
    if(isShippingSameAsBilling){
        address_ship = 1;
    }
    let shipping_address = shippingInfoObj.shippingAddress1;
    let city_ship = shippingInfoObj.shippingCity;
    let state_ship = shippingInfoObj.shippingState;
    let country_ship = shippingInfoObj.shippingCountry;
    let telephone_ship = shippingInfoObj.shippingPhone;

    let req_delivery_type = deliveryInfoObj.deliveryType;
    var delivery_type = "";
    if(req_delivery_type===1){
        delivery_type = "pickup";
    } else if(req_delivery_type===2) {
        delivery_type = "delivery";
    }
    let pickup_id = deliveryInfoObj.pickupStoreId;
    let zone = deliveryInfoObj.zoneId;
    let delivery_cost = deliveryInfoObj.deliveryCost;

    let payment_method = paymentMethod;

    let discount_type = couponInfoObj.discountType;
    let discount_amount = couponInfoObj.discountAmount;
    let discount_for = couponInfoObj.discountFor;
    let product_ids = couponInfoObj.cartProductIds;

    let client_ip_address = clientIpAddress;
    let requestApiUrl;
    let requestOptions;

    if(!isGuestCheckoutSubmited){
        //requestApiUrl = 'https://www.evisionstore.com/api/checkout/placeorder-react-beta.php';
        requestApiUrl = `${API_BASE_URL}/place-order`;
        requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify({  
                customer_id, first_name, last_name, address_1, address_2, country_id, city_id, state_id, telephone, address_ship, shipping_address,
                city_ship, state_ship, country_ship, telephone_ship, delivery_type, pickup_id, zone, delivery_cost, payment_method, discount_type, 
                discount_amount, discount_for, product_ids, client_ip_address
            })
        };
    }else{
        let email_id = billingInfoObj.billingEmail;
        let order_placed_from = "website";
        //requestApiUrl = 'https://www.evisionstore.com/api/checkout/placeorder-react-with-guest.php';
        requestApiUrl = `${API_BASE_URL}/place-order`;
        requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify({  
                product_arr, email_id, first_name, last_name, address_1, address_2, country_id, city_id, state_id, telephone, address_ship, shipping_address,
                city_ship, state_ship, country_ship, telephone_ship, delivery_type, pickup_id, zone, delivery_cost, payment_method, discount_type, 
                discount_amount, discount_for, client_ip_address, order_placed_from
            })
        };
    }

    return fetch(requestApiUrl, requestOptions)
    .then(handleCardPaymentResponse)
    .then(res => {
        return res;
    });
}

function handleCardPaymentResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.code === 401) {
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}