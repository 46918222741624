import React, { Component } from "react";
import DealSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import API_BASE_URL from "../../config/api";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { addToCart, addToCart2 } from "../../Services/Actions/cart.actions";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} style={{ ...style, display: "block", background: "red" }} onClick={onClick} />;
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} style={{ ...style, display: "block", background: "red" }} onClick={onClick} />;
}

class DesktopHomeDealSectionProp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      topProducts: [],
      isTopProductLoaded: false,
      isCartAddError: false,
      cartErrorMessage: "",
      addToCartButtonnLoader: "hidden",
      addToCartButtonnCart: "",
      addToCartButton: true,
      addToCartButtonDisabled: false,
      clickedProductId: "",
      categoryProductName: "",
    };

    this.openCartReturnPop = this.openCartReturnPop.bind(this);
    this.closeCartReturnPop = this.closeCartReturnPop.bind(this);
    this.cartUpdatePopupResOpen = this.cartUpdatePopupResOpen.bind(this);
    this.cartUpdatePopupResClose = this.cartUpdatePopupResClose.bind(this);
  }

  componentDidMount() {
    const apiUrl = `${API_BASE_URL}/specialproducts`;
    /* const requestOptions = {
            method: 'POST',
            body: JSON.stringify({"menutype": "header"})
        }; */
    fetch(apiUrl)
      .then((res) => res.json())
      .then(
        (result) => {
          //console.log(result)
          this.setState({
            topProducts: result.data,
            isTopProductLoaded: true,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );
  }

  openCartReturnPop() {
    this.setState({ showCartReturnModal: true });
  }

  closeCartReturnPop() {
    this.setState({ showCartReturnModal: false });
  }

  cartUpdatePopupResOpen() {
    this.setState({ showUpdateCartMsgModal: true });
  }

  cartUpdatePopupResClose() {
    this.setState({ showUpdateCartMsgModal: false });
  }

  handleOnAddToCart(product_id, product_name, product_image, price, quantity, brand, modelo, category_name, is_allowed_bac_credomatic) {
    this.setState({
      addToCartButtonnLoader: "",
      addToCartButtonnCart: "hidden",
      addToCartButton: true,
      addToCartButtonDisabled: true,
      cartErrorMessage: "",
      clickedProductId: product_id,
    });
    let checked_variation_product_id = 0;
    this.props.addToCart(product_id, product_name, product_image, price, quantity, checked_variation_product_id, modelo, is_allowed_bac_credomatic);

    setTimeout(
      () =>
        this.setState(
          {
            isCartAddError: this.props.isCartAddError,
          },
          () => {
            if (this.state.isCartAddError) {
              this.setState({
                cartErrorMessage: this.props.cartErrorMessage,
                addToCartButtonnLoader: "hidden",
                addToCartButtonnCart: "",
                addToCartButton: true,
                addToCartButtonDisabled: false,
                clickedProductId: "",
              });
              this.cartUpdatePopupResOpen();
              setTimeout(() => this.cartUpdatePopupResClose(), 5000);
            } else {
              this.openCartReturnPop();
              this.setState({
                addToCartButtonnLoader: "hidden",
                addToCartButtonnCart: "",
                addToCartButton: false,
                addToCartButtonDisabled: false,
                cartErrorMessage: "",
              });

              window.fbq("track", "AddToCart", {
                content_ids: [product_id],
                content_type: "product",
                value: price,
                currency: "USD",
              });

              window.ga("ec:addProduct", {
                id: modelo,
                name: brand + " " + modelo,
                price: price,
                brand: brand,
                category: category_name,
                quantity: 1,
              });
              window.ga("ec:setAction", "add");
              window.ga("send", "event", "Product List Page", "click", "addToCart");

              window.gtag("event", "add_to_cart", {
                send_to: "UA-521381-2/yuuFCLjX-aUBENbiovUC",
                value: price,
                currency: "USD",
                event_callback: true,
              });
            }
          }
        ),
      3000
    );
  }

  render() {
    const desktopdelalslidersettings = {
      //dots: true,
      infinite: true,
      autoplay: true,
      speed: 500,
      //rows: 1,
      //slidesPerRow: 2,
      slidesToShow: 3,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    };

    const { topProducts } = this.state;

    return (
      <section className="deal-container trend-deal-container desktop">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="product-title">{/* <h2>Destacados de hoy <span>Trato especial</span></h2> */}</div>
            </div>
            <div className="col-lg-3 col-md-4">
              <Link to="/" className="hoverded-card">
                <div className="trend-product">
                  <figure>
                    <img src={`${process.env.PUBLIC_URL}/images/new-images/trend1.png`} title="treding image" alt="treding image" />
                  </figure>
                  {/* <div className="trend-review">
                    <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/star.svg`} width="20" height="19" title="star" alt="star" />
                    <span>5.0 | 14 opiniones</span>
                  </div> */}
                  <div className="trend-bottom">
                    <h2>Tendencia Productos</h2>
                    <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/trend-view.svg`} width="16" height="16" title="star" alt="star" />
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-9 col-md-8">
              {(() => {
                if (this.state.isTopProductLoaded === false) {
                  return (
                    <section className="customer-logos">
                      {Array(3)
                        .fill()
                        .map((item, index) => (
                          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6" key={index}>
                            <div className="deal-link-item deal-link-item--skeleton">
                              <Skeleton height={215} width={`100%`} style={{ marginBottom: "30px" }} />
                              <Skeleton width={175} style={{ marginBottom: "10px" }} />
                              <Skeleton width={150} style={{ marginBottom: "10px" }} />
                              <Skeleton width={140} style={{ marginBottom: "10px" }} />
                              <Skeleton width={100} style={{ marginBottom: "25px" }} />
                            </div>
                          </div>
                        ))}
                    </section>
                  );
                } else {
                  return (
                    <section className="customer-logos slider">
                      <DealSlider {...desktopdelalslidersettings}>
                        {topProducts.length !== 0 ? (
                          topProducts.map((products, index) => (
                            <div className="slide" key={index}>
                              <div className="deal-link-item">
                                <Link to={`/producto/${products.modelo}`}>
                                  <figure>
                                    <a href="javascript:void(0)" className="wishlist-link">
                                      <img
                                        src={`${process.env.PUBLIC_URL}/images/new-images/icon/wishlist-icon.svg`}
                                        width="20"
                                        height="18"
                                        alt="wishlist"
                                      />
                                    </a>
                                    <img src={products.product_image} alt={products.product_name} />
                                  </figure>
                                  
                                  <div className="heading-wrap">
                                    <h2>{products.product_name}</h2>
                                    {/* <div className="review">
                                      <img src="/images/new-images/icon/star.svg" width="20" height="19" title="star" alt="star" />
                                      <span>{products.product_rating} | {products.review_count} opiniones</span>
                                    </div> */}
                                    <div className="pricecart">
                                      {products.check_spcl_prc === "yes" ? (
                                        <div className="price">
                                          $ {products.special_price} - <span className="oldprice">$ {products.price}</span>
                                        </div>
                                      ) : (
                                        <div className="price">$ {products.price}</div>
                                      )}

                                      <button
                                      onClick={(e) => {
                                          e.preventDefault();
                                          this.handleOnAddToCart(
                                            products.product_id,
                                            products.product_name,
                                            products.product_image,
                                            products.price,
                                            "1",
                                            products.brand,
                                            products.modelo,
                                            products.category_name,
                                            products.is_allowed_bac_credomatic
                                          )
                                        }
                                      }
                                      disabled={this.state.addToCartButtonDisabled}
                                      >
                                      <img
                                        src={`${process.env.PUBLIC_URL}/images/new-images/icon/top-cart.svg`}
                                        width="20"
                                        height="16"
                                        alt="Cart"
                                      />
                                    </button>
                                    </div>
                                    {/* <div className="price">$ {products.price}</div> */}

                                    {/* <h3>Ends in {products.time_ends}</h3> */}
                                  </div>
                                </Link>
                              </div>
                            </div>
                          ))
                        ) : (
                          <></>
                        )}
                      </DealSlider>
                      <Modal show={this.state.showUpdateCartMsgModal} onHide={this.cartUpdatePopupResClose} animation={true}>
                        <Modal.Header closeButton>
                          <Modal.Title>Oops!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <p style={{ textAlign: "center" }}>{this.state.cartErrorMessage}</p>
                        </Modal.Body>
                      </Modal>

                      <Modal show={this.state.showCartReturnModal} onHide={this.closeCartReturnPop} animation={true}>
                        <Modal.Header>
                          <button type="button" className="close" onClick={this.closeCartReturnPop}>
                            &times;
                          </button>
                          <Modal.Title>
                            <i className="fa fa-check" aria-hidden="true"></i> El producto ha sido agregado al carrito.
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <p style={{ display: "flex", alignItems: "center", justifyContent: "center", flexWrap: "wrap", gap: "20px", marginTop: "17px", textAlign: "center" }}>
                            <Link to="/cart" className="btn btn-primary btn-lg">
                              &nbsp;&nbsp;Proceder a Pagar <i className="fa fa-arrow-right" aria-hidden="true"></i>&nbsp;&nbsp;
                            </Link>
                            <button className="btn btn-success btn-lg" onClick={this.closeCartReturnPop}>
                              &nbsp;&nbsp;<i className="fa fa-shopping-basket" aria-hidden="true"></i> Seguir Comprando&nbsp;&nbsp;
                            </button>
                          </p>
                        </Modal.Body>
                      </Modal>
                    </section>
                  );
                }
              })()}
            </div>
          </div>
        </div>
      </section>
    );
  }
}
function mapStateToProps(state) {
    localStorage.setItem("cartData", JSON.stringify(state.cart.cartData));
    return {
      isLoggedIn: state.user.isLoggedIn,
      loggedInUserEmail: typeof state.user.user === "undefined" ? "" : state.user.user.email,
      isError: state.user.isError,
      errorMessage: state.user.errorMessage,
      cartData: state.cart.cartData,
      filteredCategoryProducts: state.filter.filteredProducts,
      isFilterProductsLoaded: state.filter.isFilterProductsLoaded,
      isCartAddError: state.cart.isCartAddError,
      cartErrorMessage: state.cart.cartErrorMessage,
    };
}
  
  const MapDispachToProps = (dispatch) => ({
    addToCart: () => dispatch(addToCart()),
  });
  
  const actionCreators = {
    addToCart: addToCart2,
    //resetFilterByCategorySlug: resetFilterByCategorySlug,
    //setBrandOptions: setBrandOptions,
    //setProductsById: setProductsById,
  };
  
  const DesktopHomeDealSection = connect(mapStateToProps, actionCreators)(DesktopHomeDealSectionProp);
  export default DesktopHomeDealSection;