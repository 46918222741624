import React, {Component} from 'react';
import PartnersSlider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

export default class PartnersListSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logoerror: null,
            customerlogos: []
        }
    }

    componentDidMount() {
        const apiUrl = 'https://www.evisionstore.com/api/home/dashboard.php';

        fetch(apiUrl)
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    customerlogos: result.home_brands
                });
            },
            (error) => {
                this.setState({ logoerror: error });
            }
        )
    }

    render(){
        const { logoerror, customerlogos } = this.state;

        const partnersslidersettings = {
            infinite: true,
            autoplay: true,
            speed: 600,
            slidesToShow: 8,
            slidesToScroll: 1,
            dots: false,
            responsive: [
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    autoplay: true
                  }
                }
            ]
        };

        return(
            <section className="partner-container">
                <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="product-title common-section-title">
                            <h2>Tiendas de marcas</h2>
                        </div>
                    </div>
                    <section className="customer-logos">
                        <PartnersSlider {...partnersslidersettings}>
                            <div key='hisense'>
                                <Link to={`/brandshop/hisense`} >
                                    <picture>
                                        <img src="/images/marcalogo/marca_logo-11.png" alt="Hisense"/>
                                    </picture>
                                </Link>
                            </div>
                            <div key='lg'>
                                <Link to={`/brandshop/lg`} >
                                    <picture>
                                        <img src="/images/marcalogo/marca_logo-7.png" alt="LG"/>
                                    </picture>
                                </Link>
                            </div>
                            <div key='samsung'>
                                <Link to={`/brandshop/samsung`} >
                                    <picture>
                                        <img src="/images/marcalogo/marca_logo-4.png" alt="Samsung"/>
                                    </picture>
                                </Link>
                            </div>
                            <div key='oster'>
                                <Link to={`/brandshop/oster`} >
                                    <picture>
                                        <img src="/images/marcalogo/marca_logo-8.png" alt="Oster"/>
                                    </picture>
                                </Link>
                            </div>
                            <div key='sankey'>
                                <Link to={`/brandshop/sankey`} >
                                    <picture>
                                        <img src="/images/marcalogo/marca_logo-6.png" alt="Sankey"/>
                                    </picture>
                                </Link>
                            </div>
                            <div key='drija'>
                                <Link to={`/brandshop/drija`} >
                                    <picture>
                                        <img src="/images/marcalogo/marca_logo-12.png" alt="Drija"/>
                                    </picture>
                                </Link>
                            </div>
                            <div key='canon'>
                                <Link to={`/brandshop/canon`} >
                                    <picture>
                                        <img src="/images/marcalogo/marca_logo-1.png" alt="Canon"/>
                                    </picture>
                                </Link>
                            </div>
                            <div key='frigidaire'>
                                <Link to={`/brandshop/frigidaire`} >
                                    <picture>
                                        <img src="/images/marcalogo/marca_logo-13.png" className="frigidaire-img" alt="Frigidaire"/>
                                    </picture>
                                </Link>
                            </div>
                            <div key='tcl'>
                                <Link to={`/brandshop/tcl`} >
                                    <picture>
                                        <img src="/images/marcalogo/tcl.png" alt="TCL"/>
                                    </picture>
                                </Link>
                            </div>
                            {/* {customerlogos.map(customerlogos => (
                                <div key={customerlogos.brand_id}>
                                    <Link to={`/brandshop/${customerlogos.brand_name.toString().toLowerCase()}`} ><picture><img src={customerlogos.brand_image} alt={customerlogos.brand_name}/></picture></Link>
                                </div>
                            ))} */}
                        </PartnersSlider>
                    </section>
                </div>
                </div>
            </section>
        )
    }
}