import React, {Component} from 'react';
import ImageWithLinkListSection from './ImageWithLinkListSection';
import MobileHomeDealSection from './MobileHomeDealSection';
import DesktopHomeOfferSection from './DesktopHomeOfferSection';
import ProductCategoryListSection from './ProductCategoryListSection';
import MobileHomeOfferSection from './MobileHomeOfferSection';
import DesktopHomeDealSection from './DesktopHomeDealSection';
import HomeNewProductSection from './HomeNewProductSection';
import PartnersListSection from './PartnersListSection';
import ContactInfoSection from './ContactInfoSection';
import BrandShopLogosSection from './BrandShopLogosSection';
import RecomendedShopSection from './RecomendedShopSection';
import MostDealSection from './MostDealSection';
import ExperienceHomeShopSection from './ExperienceHomeShopSection';
import MobileHomePopularSection from './MobileHomePopularlSection';
import MainSlider from '../Header/SliderSlick';

import {isMobile} from 'react-device-detect';
import { Helmet } from 'react-helmet';
const TITLE = 'E-Vision / Compra Online';

export default class Home extends Component {
    
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount(){
        window.Intercom('update', {
            "hide_default_launcher": true
        });
    }

    renderContent = () => {
        if (isMobile) {
            return <div className="wraper">
                        <Helmet>
                            <title>{ TITLE }</title>
                        </Helmet>

                        <HomeNewProductSection />
                        <MainSlider />
                        <ProductCategoryListSection />
                        {/* <BrandShopLogosSection /> */}
                        {/* <ImageWithLinkListSection /> */}
                        <MobileHomeDealSection />
                        <PartnersListSection />
                        <RecomendedShopSection/>
                        <MobileHomeOfferSection />
                        <MobileHomePopularSection />
                        <ExperienceHomeShopSection/>
                        <ContactInfoSection />
                    </div>
        }
        return <div className="wraper">
                    <Helmet>
                        <title>{ TITLE }</title>
                    </Helmet>

                    <HomeNewProductSection />
                    <MainSlider />
                    <ProductCategoryListSection />
                    <DesktopHomeDealSection />
                    {/* <BrandShopLogosSection /> */}
                    <PartnersListSection />
                    <RecomendedShopSection/>
                    <DesktopHomeOfferSection />
                    <MostDealSection />
                    <ExperienceHomeShopSection/>
                    {/* <ImageWithLinkListSection /> */}
                    {/* <PartnersListSection /> */}
                    <ContactInfoSection />
                </div>
    }
    render(){
        return this.renderContent();
    }
}