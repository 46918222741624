import React, {Component} from 'react';
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { Modal, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import Pagination from './ProductPagination';
import { addToCart, addToCart2 } from '../../Services/Actions/cart.actions';
import { resetFilter, setBrandOptions, setProductsById } from '../../Services/Actions/filter.actions';
// import { Helmet } from 'react-helmet';
import Skeleton from "react-loading-skeleton";
import { redirectTo } from '../Header/redirecturl';
import API_BASE_URL from "../../config/api";

class ProductRightCategoryListComp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {}, isProductError: null,
            isProductsLoaded : false,
            categoryProducts: [],
            isCartAddError: false, cartErrorMessage: '', addToCartButtonnLoader: "hidden", addToCartButtonnCart: '', addToCartButton: true, addToCartButtonDisabled: false,
            pageOfItems: [], showUpdateCartMsgModal: false, showNotifyModal: false,
            notifyProductSubmitted: false, notifytoemail: this.props.loggedInUserEmail, notifytoproduct: '', notifyProdLoader: "hidden", notifyApiSuccessResponse: "",
            clickedProductId: '',
        }

        this.openNotifyPop = this.openNotifyPop.bind(this);
        this.closeNotifyPop = this.closeNotifyPop.bind(this);
        this.handleOnNotifyEmailInputChange = this.handleOnNotifyEmailInputChange.bind(this);
        this.handleOnNotifyProductSubmit = this.handleOnNotifyProductSubmit.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.openCartReturnPop = this.openCartReturnPop.bind(this);
        this.closeCartReturnPop = this.closeCartReturnPop.bind(this);
        this.cartUpdatePopupResOpen = this.cartUpdatePopupResOpen.bind(this);
        this.cartUpdatePopupResClose = this.cartUpdatePopupResClose.bind(this);
    }

    openNotifyPop(product_id) {
        this.setState({showNotifyModal: true, notifytoproduct: product_id});
    }

    closeNotifyPop() {
        this.setState({showNotifyModal: false});
    }

    handleOnNotifyEmailInputChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleNotifyProductFormValidation(){
        let notifytoemail = this.state.notifytoemail;
        let errors = {};
        let notifyProductFormIsValid = true;
    
        if(typeof notifytoemail !== "undefined"){
           let lastAtPos = notifytoemail.lastIndexOf('@');
           let lastDotPos = notifytoemail.lastIndexOf('.');
    
           if (!(lastAtPos < lastDotPos && lastAtPos > 0 && notifytoemail.indexOf('@@') === -1 && lastDotPos > 2 && (notifytoemail.length - lastDotPos) > 2)) {
              notifyProductFormIsValid = false;
              errors["notifytoemail"] = "Your registered email id is not valid";
            }
        } else {
            notifyProductFormIsValid = false;
            errors["notifytoemail"] = "Please enter your registered email id";
        } 
    
       this.setState({errors: errors});
       return notifyProductFormIsValid;
    }

    handleOnNotifyProductSubmit(e){
        e.preventDefault();
        this.setState({ notifyProductSubmitted: true });

        if(this.handleNotifyProductFormValidation()){
            this.setState({ notifyProdLoader: "" });
            let customer_email = this.state.notifytoemail;
            //let product_id = this.props.match.params.productId;
            let product_id = this.state.notifytoproduct;

            //const apiUrl = 'https://www.evisionstore.com/api/user/account/notifyproemail.php';
            const apiUrl = `${API_BASE_URL}/notify-product`;
            fetch(apiUrl, {
                method: 'POST',
                body: JSON.stringify({
                    "customer_email": customer_email,
                    "product_id": product_id
                })
            })
            .then(res => res.json())
            .then(
                (result) => {
                    if(result.code===200){
                        //console.log(result);
                        this.setState({
                            notifyApiSuccessResponse: result.message,
                            notifyProdLoader: "hidden"
                        });
                    }else{
                        this.setState({
                            notifyApiSuccessResponse: result.message,
                            notifyProdLoader: "hidden"
                        });
                    }
                },
                (error) => {
                    this.setState({ error });
                }
            )
        }
    }

    onChangePage(pageOfItems) {
        // update state with new page of items
        this.setState({ pageOfItems: pageOfItems });
    }

    handleOnProductRedirect(productId){
        return redirectTo("/producto/"+productId);
    }

    componentDidMount() {
        let categoryName = this.props.match.params.categoryName;
        //let brandName = this.props.match.params.brandName;

        let brandName = "";
        if(this.props.match.params.brandName !== undefined){
            brandName = this.props.match.params.brandName;
        }

        this.props.resetFilter(brandName);
        //const apiUrl = 'https://www.evisionstore.com/api/product/list-landing-products.php';
        const apiUrl = `${API_BASE_URL}/landing-products`;
        let requestOptions;

        if(brandName !== ""){
            requestOptions = {
                method: 'POST',
                body: JSON.stringify({"category_name": categoryName, "brand_name": brandName})
            };
        }else{
            requestOptions = {
                method: 'POST',
                body: JSON.stringify({"category_name": categoryName})
            };
        }

        fetch(apiUrl, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                //console.log(result.product_list);

                this.setState({
                    categoryProducts: result.data.product_list,
                    isProductsLoaded: true
                });
                //localStorage.setItem('productsData', JSON.stringify(result.product_list));
                this.props.setProductsById(result.data.product_list);
                //console.log(result.product_list)
                var res = result.data.product_list;
                let mymap = new Map(); 
  
                let unique = res.filter(el => { 
                    const val = mymap.get(el.brand); 
                    if(val) { 
                        if(el.id < val) { 
                            mymap.delete(el.brand); 
                            mymap.set(el.brand, el.brand); 
                            return true; 
                        } else { 
                            return false; 
                        } 
                    } 
                    mymap.set(el.brand, el.brand); 
                    return true; 
                }); 

                var resArr = [];
                unique.filter(function(item){
                    var i = resArr.findIndex(x => x.brand == item.brand);
                    if(i <= -1){
                        resArr.push({name: item.brand, id: item.brand});
                    }
                    return null;
                });
                this.props.setBrandOptions(resArr);
                //console.log(resArr);
            },
            (error) => {
                this.setState({ isProductError: error });
            }
        )
    }

    componentDidUpdate(prevProps, prevState) {
        // only update chart if the data has changed
        if (prevProps.match.params.brandName !== this.props.match.params.brandName) {
            
            this.setState({
                isProductsLoaded: false
            });

            let categoryName = this.props.match.params.categoryName;
            
            let brandName = "";
            if(this.props.match.params.brandName !== undefined){
                brandName = this.props.match.params.brandName;
            }

            this.props.resetFilter(brandName);
            //const apiUrl = 'https://www.evisionstore.com/api/product/list-landing-products.php';
            const apiUrl = `${API_BASE_URL}/landing-products`;
            let requestOptions;

            if(brandName !== ""){
                requestOptions = {
                    method: 'POST',
                    body: JSON.stringify({"category_name": categoryName, "brand_name": brandName})
                };
            }else{
                requestOptions = {
                    method: 'POST',
                    body: JSON.stringify({"category_name": categoryName})
                };
            }

            fetch(apiUrl, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        categoryProducts: result.data.product_list,
                        isProductsLoaded: true
                    });
                    //localStorage.setItem('productsData', JSON.stringify(result.product_list));
                    this.props.setProductsById(result.data.product_list);
                    //Added this code for to get unique category name from array
                    var res = result.data.product_list;
                    let mymap = new Map(); 
    
                    let unique = res.filter(el => { 
                        const val = mymap.get(el.brand); 
                        if(val) { 
                            if(el.id < val) { 
                                mymap.delete(el.brand); 
                                mymap.set(el.brand, el.brand); 
                                return true; 
                            } else { 
                                return false; 
                            } 
                        } 
                        mymap.set(el.brand, el.brand); 
                        return true; 
                    }); 

                    var resArr = [];
                    unique.filter(function(item){
                        var i = resArr.findIndex(x => x.brand == item.brand);
                        if(i <= -1){
                            resArr.push({name: item.brand, id: item.brand});
                        }
                        return null;
                    });
                    this.props.setBrandOptions(resArr);
                    //Added this code for to get unique category name from array
                },
                (error) => {
                    this.setState({ isProductError: error });
                }
            )
        }
    }

    openCartReturnPop() {
        this.setState({showCartReturnModal: true});
    }
    
    closeCartReturnPop() {
        this.setState({showCartReturnModal: false});
    }

    cartUpdatePopupResOpen() {
        this.setState({showUpdateCartMsgModal: true});
    }
    
    cartUpdatePopupResClose() {
        this.setState({showUpdateCartMsgModal: false});
    }

    handleOnAddToCart(product_id, product_name, product_image, price, quantity) {
        this.setState({addToCartButtonnLoader: '', addToCartButtonnCart: 'hidden', addToCartButton: true, addToCartButtonDisabled: true, cartErrorMessage: '', clickedProductId: product_id})
        this.props.addToCart(product_id, product_name, product_image, price, quantity);

        setTimeout(()=> 
            this.setState({
                isCartAddError: this.props.isCartAddError 
            },() => {
                if(this.state.isCartAddError){
                    this.setState({cartErrorMessage: this.props.cartErrorMessage, addToCartButtonnLoader: 'hidden', addToCartButtonnCart: '', addToCartButton: true, addToCartButtonDisabled: false, clickedProductId: ''});
                    this.cartUpdatePopupResOpen();
                    setTimeout(()=> 
                        this.cartUpdatePopupResClose(),
                    5000);
                } else {
                    this.openCartReturnPop();
                    this.setState({addToCartButtonnLoader: 'hidden', addToCartButtonnCart: '', addToCartButton: false, addToCartButtonDisabled: false, cartErrorMessage: ''});
                }
            }),
        3000);
    }

    render(){
        const { isProductsLoaded, categoryProducts, pageOfItems, notifyProductSubmitted, notifytoemail, notifytoproduct } = this.state;
        const { match, location, history, cartData, filteredCategoryProducts, isFilterProductsLoaded } = this.props;
        //console.log(cartData);

        if(isProductsLoaded && isFilterProductsLoaded===false){
            if (categoryProducts && categoryProducts.length > 0) {
                
                return(
                    <>
                        <div className="row">
                        {pageOfItems.map((localState, index) => (
                            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem" key={localState.product_id}>
                                <meta itemProp="position" content={(index + 1)} />
                                <div className="deal-link-item" itemProp="item" itemScope itemType="http://schema.org/Product">
                                    {/* <Link to={`/product/${localState.product_id}`}> */}
                                        <figure>
                                            {(() => {
                                                let modelNumber = localState.modelo;
                                                let checkSlash = "/";
                                                let setModelNumber;
                                                if(modelNumber.indexOf(checkSlash) !== -1){
                                                    setModelNumber = localState.modeloch;
                                                }else{
                                                    setModelNumber = localState.modelo;
                                                }
                                                return (
                                                    <>
                                                    <a href="javascript:void(0)" className="wishlist-link">
                                                        <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/wishlist-icon.svg`} width="20" height="18" alt="wishlist" />
                                                    </a>
                                                    <meta itemProp="url" content={`https://www.evisionstore.com/producto/${setModelNumber}`} />
                                                    <Link to="#" onClick={() => this.handleOnProductRedirect(setModelNumber)}>
                                                        <img itemProp="image" src={localState.product_image} alt={localState.product_name} />
                                                    </Link>
                                                    </>
                                                )
                                            })()}
                                        </figure>
                                        <div className="heading-wrap">
                                            <h2 itemProp="name">
                                                {(() => {
                                                    let modelNumber = localState.modelo;
                                                    let checkSlash = "/";
                                                    let setModelNumber;
                                                    if(modelNumber.indexOf(checkSlash) !== -1){
                                                        setModelNumber = localState.modeloch;
                                                    }else{
                                                        setModelNumber = localState.modelo;
                                                    }
                                                    return (
                                                        <Link to="#" onClick={() => this.handleOnProductRedirect(setModelNumber)} dangerouslySetInnerHTML={{__html: localState.product_name }} />
                                                    )
                                                })()}
                                            </h2>
                                            <div className="review">
                                                <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/star.svg`} width="20" height="19" title="star" alt="star" />
                                                <span>{localState.product_rating} | {localState.review_count} opiniones</span>
                                            </div>
                                            <div className="pricecart">
                                            {(() => {
                                                if (localState.price == '0.00'){
                                                    return (<></>)
                                                } else {
                                                    return (
                                                        <div className="price" itemProp="offers" itemScope="" itemType="http://schema.org/Offer">
                                                            <meta itemProp="price" content={localState.price} /> 
                                                            <meta itemProp="priceCurrency" content="USD" />
                                                            {localState.regular_price ? (
                                                                <span style={{textDecoration:'line-through', fontWeight:'400', fontSize:'15px', marginRight:'10px', color:'#4d4a4a'}}>
                                                                    $ {localState.regular_price}
                                                                </span>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            $ {localState.price}
                                                        </div>
                                                    )
                                                }
                                            })()}
                                            
                                            {/* <h3 itemProp="description">{localState.short_description}</h3> */}
                                            {(() => {
                                            if (localState.allow_purchase == '1'){
                                                if(localState.qty > 0){
                                                    if(this.state.addToCartButton){
                                                        return (
                                                        <button onClick={() => this.handleOnAddToCart(localState.product_id, localState.product_name, localState.product_image, localState.price, "1")} disabled={this.state.addToCartButtonDisabled}>
                                                     
                                                            {(() => {
                                                                if (this.state.clickedProductId == localState.product_id && this.state.addToCartButtonnLoader === ""){
                                                                    return (
                                                                        <>
                                                                        <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/top-cart.svg`} width="20" height="16" alt="Cart" />
                                                                        </>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <>
                                                                        <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/top-cart.svg`} width="20" height="16" alt="Cart" />
                                                                        </>
                                                                    )
                                                                }
                                                            })()}
                                                        </button>
                                                        )
                                                    } else if(!this.state.addToCartButton && this.state.clickedProductId === localState.product_id){
                                                        return (
                                                        <Link to="/cart">View Cart <i className="fa fa-long-arrow-right" aria-hidden="true"></i></Link>
                                                        )
                                                    } else if(!this.state.addToCartButton && this.state.clickedProductId != localState.product_id){
                                                        return (
                                                            <button onClick={() => this.handleOnAddToCart(localState.product_id, localState.product_name, localState.product_image, localState.price, "1")} >
                                                                <i className="fa fa-shopping-cart" ></i> Agregar al carrito 
                                                            </button>
                                                            )
                                                    } else {
                                                        return null;
                                                    }
                                                }else{
                                                    return (
                                                    <>
                                                    <div>
                                                    <span className="outofstock">
                                                        <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" stroke="#de1d1d"><g strokeWidth="0"/><g strokeLinecap="round" strokeLinejoin="round"/><g><path stroke="#de1d1d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v4m0 3v.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.068C2.842 18.4 3.808 20 5.313 20Z"/> </g></svg>
                                                        Fuera de Stock
                                                    </span>
                                                    <button onClick={() => this.openNotifyPop(localState.product_id)} className="tooltip-btn">
                                                        <svg width="24px" height="24px" viewBox="0 0 24 24" fill="#de1d1d"><g strokeWidth="0"/><g strokeLinecap="round" strokeLinejoin="round"/><g> <title/> <g> <g> <g> <path d="M18.9,11.2s0-8.7-6.9-8.7-6.9,8.7-6.9,8.7v3.9L2.5,17.5h19l-2.6-2.4Z" fill="none" stroke="#de1d1d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/> <path d="M14.5,20.5s-.5,1-2.5,1-2.5-1-2.5-1" fill="none" stroke="#de1d1d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/> </g> </g> </g> </g></svg>  
                                                        <span className="tooltip-text">Recibir notificación sobre disponibilidad</span>
                                                    </button>
                                                    </div>
                                                    </>
                                                    )
                                                }
                                            }else{
                                                return (
                                                    <>
                                                    <div>
                                                    <span className="outofstock">
                                                        <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" stroke="#de1d1d"><g strokeWidth="0"/><g strokeLinecap="round" strokeLinejoin="round"/><g><path stroke="#de1d1d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v4m0 3v.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.068C2.842 18.4 3.808 20 5.313 20Z"/> </g></svg>
                                                        Fuera de Stock
                                                    </span>
                                                    <button onClick={() => this.openNotifyPop(localState.product_id)} className="tooltip-btn">
                                                        <svg width="24px" height="24px" viewBox="0 0 24 24" fill="#de1d1d"><g strokeWidth="0"/><g strokeLinecap="round" strokeLinejoin="round"/><g> <title/> <g> <g> <g> <path d="M18.9,11.2s0-8.7-6.9-8.7-6.9,8.7-6.9,8.7v3.9L2.5,17.5h19l-2.6-2.4Z" fill="none" stroke="#de1d1d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/> <path d="M14.5,20.5s-.5,1-2.5,1-2.5-1-2.5-1" fill="none" stroke="#de1d1d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/> </g> </g> </g> </g></svg>  
                                                        <span className="tooltip-text">Recibir notificación sobre disponibilidad</span>
                                                    </button>
                                                    </div>
                                                    </>
                                                    )
                                            }
                                            })()}
                                            </div>
                                        </div>
                                    {/* </Link> */}
                                </div>	
                            </div>
                            ))}

                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <Pagination items={categoryProducts} onChangePage={this.onChangePage} />
                            </div>
                        </div>

                        <Modal show={this.state.showUpdateCartMsgModal} onHide={this.cartUpdatePopupResClose} animation={true}>                          
                            <Modal.Header closeButton>
                                <Modal.Title>Oops!</Modal.Title>
                            </Modal.Header>
                            <Modal.Body><p style={{textAlign:'center'}}>{this.state.cartErrorMessage}</p></Modal.Body>     
                        </Modal>

                        <Modal show={this.state.showCartReturnModal} onHide={this.closeCartReturnPop} animation={true}>                          
                            <Modal.Header>
                                <button type="button" className="close" onClick={this.closeCartReturnPop}>&times;</button>
                                <Modal.Title>
                                    <i className="fa fa-check" aria-hidden="true"></i> El producto ha sido agregado al carrito.
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            <p style={{display: "flex", alignItems: "center", justifyContent: "center", flexWrap: "wrap", gap: "20px", marginTop: '17px', textAlign: 'center'}}>
                            <Link to="/cart" className="btn btn-primary btn-lg">
                                &nbsp;&nbsp;Proceder a Pagar <i className="fa fa-arrow-right" aria-hidden="true"></i>&nbsp;&nbsp;
                            </Link>
                            <button className="btn btn-success btn-lg" onClick={this.closeCartReturnPop}>
                                &nbsp;&nbsp;<i className="fa fa-shopping-basket" aria-hidden="true"></i> Seguir Comprando&nbsp;&nbsp;
                            </button>
                            </p>
                            </Modal.Body>     
                        </Modal>

                        <Modal show={this.state.showNotifyModal} onHide={this.closeNotifyPop} animation={true}>                          
                            <Modal.Body>
                                <div className=""> 
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <button type="button" className="close" onClick={this.closeNotifyPop}>&times;</button>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{padding:"20px"}}>
                                        <form className="notify_prod_form" id="forgot-password-form" onSubmit={this.handleOnNotifyProductSubmit}>
                                            <div className="col-xs-12">
                                            <h4 style={{fontSize:"20.5px"}}>Notificar cuando el producto esté disponible</h4>
                                            <p>Suscríbase a este producto para recibir un correo electrónico una vez que esté disponible</p>
                                            </div>
                                            <div className="col-xs-12">
                                                <Form.Group controlId="formGroupCardNumber">
                                                    <Form.Control type="hidden" name="notifytoproduct" value={notifytoproduct} />
                                                    <Form.Control type="email" name="notifytoemail" value={notifytoemail} onChange={this.handleOnNotifyEmailInputChange} placeholder="Enter Your Email" className={'form-control' + (notifyProductSubmitted && !notifytoemail ? ' has-error' : '')} maxLength="60"/>
                                                    <span style={{color: "red"}}>{this.state.errors["notifytoemail"]}</span>
                                                    <span style={{color: "green"}}>{this.state.notifyApiSuccessResponse}</span>
                                                </Form.Group>
                                            </div>
                                            
                                            <div className="col-xs-12 signup-btn-wrap">
                                                <button className="btn" value="Forgot Password" type="submit">
                                                    <i className={`fa fa-refresh fa-spin ${ this.state.notifyProdLoader }`} style={{fontSize:"17px"}}></i> Notificarme
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </Modal.Body>       
                        </Modal>
                    </>
                )

            }else{

                return(
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" >	
                        <h3>No Product Found.</h3>
                        </div>
                    </div>
                )

            }
        }else if(isProductsLoaded && isFilterProductsLoaded){
            if (filteredCategoryProducts && filteredCategoryProducts.length > 0) {
                
                return(
                    <>
                    <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 catagory-container-right">
                        <div className="row">
                        {pageOfItems.map((localState, index) => (
                            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12" itemprop="itemListElement" itemScope itemType="http://schema.org/ListItem" key={localState.product_id}>
                                <meta itemprop="position" content={(index + 1)} />
                                <div className="deal-link-item" itemprop="item" itemScope itemType="http://schema.org/Product">
                                    {/* <Link to={`/product/${localState.product_id}`}> */}
                                        <figure>
                                            {(() => {
                                                let modelNumber = localState.modelo;
                                                let checkSlash = "/";
                                                let setModelNumber;
                                                if(modelNumber.indexOf(checkSlash) !== -1){
                                                    setModelNumber = localState.modeloch;
                                                }else{
                                                    setModelNumber = localState.modelo;
                                                }
                                                return (
                                                    <>
                                                    <a href="javascript:void(0)" className="wishlist-link">
                                                        <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/wishlist-icon.svg`} width="20" height="18" alt="wishlist" />
                                                    </a>
                                                    <meta itemprop="url" content={`https://www.evisionstore.com/producto/${setModelNumber}`} />
                                                    <Link to="#" onClick={() => this.handleOnProductRedirect(setModelNumber)}>
                                                        <img itemprop="image" src={localState.product_image} alt={localState.product_name} />
                                                    </Link>
                                                    </>
                                                )
                                            })()}
                                        </figure>
                                        <div className="heading-wrap">
                                            <h2 itemprop="name">
                                                {(() => {
                                                    let modelNumber = localState.modelo;
                                                    let checkSlash = "/";
                                                    let setModelNumber;
                                                    if(modelNumber.indexOf(checkSlash) !== -1){
                                                        setModelNumber = localState.modeloch;
                                                    }else{
                                                        setModelNumber = localState.modelo;
                                                    }
                                                    return (
                                                        <Link to="#" onClick={() => this.handleOnProductRedirect(setModelNumber)}>
                                                            {localState.product_name}
                                                        </Link>
                                                    )
                                                })()}
                                            </h2>
                                            <div className="review">
                                                <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/star.svg`} width="20" height="19" title="star" alt="star" />
                                                <span>{localState.product_rating} | {localState.review_count} opiniones</span>
                                            </div>
                                            <div className="pricecart">
                                            {(() => {
                                                if (localState.price == '0.00'){
                                                    return (<></>)
                                                } else {
                                                    return (
                                                        <div className="price" itemprop="offers" itemScope="" itemType="http://schema.org/Offer">
                                                            <meta itemprop="price" content={localState.price} /> 
                                                            <meta itemprop="priceCurrency" content="USD" />
                                                            {localState.regular_price ? (
                                                                <span style={{textDecoration:'line-through', fontWeight:'400', fontSize:'15px', marginRight:'10px', color:'#4d4a4a'}}>
                                                                    $ {localState.regular_price}
                                                                </span>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            $ {localState.price}
                                                        </div>
                                                    )
                                                }
                                            })()}
                                            
                                            {/* <h3 itemprop="description">{localState.short_description}</h3> */}
                                            {(() => {
                                            if (localState.allow_purchase == '1'){
                                                if(localState.qty > 0){
                                                    if(this.state.addToCartButton){
                                                        return (
                                                        <button onClick={() => this.handleOnAddToCart(localState.product_id, localState.product_name, localState.product_image, localState.price, "1")} disabled={this.state.addToCartButtonDisabled}>
                                                     
                                                            {(() => {
                                                                if (this.state.clickedProductId == localState.product_id && this.state.addToCartButtonnLoader === ""){
                                                                    return (
                                                                        <>
                                                                        <i className="fa fa-refresh fa-spin"></i> Agregar al carrito<img src={`${process.env.PUBLIC_URL}/images/new-images/icon/top-cart.svg`} width="20" height="16" alt="Cart" />
                                                                        </>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <>
                                                                        <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/top-cart.svg`} width="20" height="16" alt="Cart" />
                                                                        </>
                                                                    )
                                                                }
                                                            })()}
                                                        </button>
                                                        )
                                                    } else if(!this.state.addToCartButton && this.state.clickedProductId === localState.product_id){
                                                        return (
                                                        <Link to="/cart">View Cart <i className="fa fa-long-arrow-right" aria-hidden="true"></i></Link>
                                                        )
                                                    } else if(!this.state.addToCartButton && this.state.clickedProductId != localState.product_id){
                                                        return (
                                                            <button onClick={() => this.handleOnAddToCart(localState.product_id, localState.product_name, localState.product_image, localState.price, "1")} >
                                                                <i className="fa fa-shopping-cart" ></i> Agregar al carrito 
                                                            </button>
                                                            )
                                                    } else {
                                                        return null;
                                                    }
                                                }else{
                                                    return (
                                                        <>
                                                        <div>
                                                        <span className="outofstock">
                                                            <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" stroke="#de1d1d"><g strokeWidth="0"/><g strokeLinecap="round" strokeLinejoin="round"/><g><path stroke="#de1d1d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v4m0 3v.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.068C2.842 18.4 3.808 20 5.313 20Z"/> </g></svg>
                                                            Fuera de Stock
                                                        </span>
                                                        <button onClick={() => this.openNotifyPop(localState.product_id)} className="tooltip-btn">
                                                            <svg width="24px" height="24px" viewBox="0 0 24 24" fill="#de1d1d"><g strokeWidth="0"/><g strokeLinecap="round" strokeLinejoin="round"/><g> <title/> <g> <g> <g> <path d="M18.9,11.2s0-8.7-6.9-8.7-6.9,8.7-6.9,8.7v3.9L2.5,17.5h19l-2.6-2.4Z" fill="none" stroke="#de1d1d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/> <path d="M14.5,20.5s-.5,1-2.5,1-2.5-1-2.5-1" fill="none" stroke="#de1d1d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/> </g> </g> </g> </g></svg>  
                                                            <span className="tooltip-text">Recibir notificación sobre disponibilidad</span>
                                                        </button>
                                                        </div>
                                                        </>
                                                        )
                                                }
                                            }else{
                                                return (
                                                    <>
                                                    <div>
                                                    <span className="outofstock">
                                                        <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" stroke="#de1d1d"><g strokeWidth="0"/><g strokeLinecap="round" strokeLinejoin="round"/><g><path stroke="#de1d1d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v4m0 3v.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.068C2.842 18.4 3.808 20 5.313 20Z"/> </g></svg>
                                                        Fuera de Stock
                                                    </span>
                                                    <button onClick={() => this.openNotifyPop(localState.product_id)} className="tooltip-btn">
                                                        <svg width="24px" height="24px" viewBox="0 0 24 24" fill="#de1d1d"><g strokeWidth="0"/><g strokeLinecap="round" strokeLinejoin="round"/><g> <title/> <g> <g> <g> <path d="M18.9,11.2s0-8.7-6.9-8.7-6.9,8.7-6.9,8.7v3.9L2.5,17.5h19l-2.6-2.4Z" fill="none" stroke="#de1d1d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/> <path d="M14.5,20.5s-.5,1-2.5,1-2.5-1-2.5-1" fill="none" stroke="#de1d1d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/> </g> </g> </g> </g></svg>  
                                                        <span className="tooltip-text">Recibir notificación sobre disponibilidad</span>
                                                    </button>
                                                    </div>
                                                    </>
                                                    )
                                            }
                                            })()}
                                            </div>
                                        </div>
                                    {/* </Link> */}
                                </div>	
                            </div>
                            ))}

                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <Pagination items={filteredCategoryProducts} onChangePage={this.onChangePage} />
                            </div>
                        </div>
                    </div>

                    <Modal show={this.state.showUpdateCartMsgModal} onHide={this.cartUpdatePopupResClose} animation={true}>                          
                        <Modal.Header closeButton>
                            <Modal.Title>Oops!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body><p style={{textAlign:'center'}}>{this.state.cartErrorMessage}</p></Modal.Body>     
                    </Modal>

                    <Modal show={this.state.showCartReturnModal} onHide={this.closeCartReturnPop} animation={true}>                          
                        <Modal.Header>
                            <button type="button" className="close" onClick={this.closeCartReturnPop}>&times;</button>
                            <Modal.Title>
                                <i className="fa fa-check" aria-hidden="true"></i> El producto ha sido agregado al carrito.
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <p style={{display: "flex", alignItems: "center", justifyContent: "center", flexWrap: "wrap", gap: "20px", marginTop: '17px', textAlign: 'center'}}>
                        <Link to="/cart" className="btn btn-primary btn-lg">
                            &nbsp;&nbsp;Proceder a Pagar <i className="fa fa-arrow-right" aria-hidden="true"></i>&nbsp;&nbsp;
                        </Link>
                        <button className="btn btn-success btn-lg" onClick={this.closeCartReturnPop}>
                            &nbsp;&nbsp;<i className="fa fa-shopping-basket" aria-hidden="true"></i> Seguir Comprando&nbsp;&nbsp;
                        </button>
                        </p>
                        </Modal.Body>     
                    </Modal>

                    <Modal show={this.state.showNotifyModal} onHide={this.closeNotifyPop} animation={true}>                          
                        <Modal.Body>
                            <div className=""> 
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <button type="button" className="close" onClick={this.closeNotifyPop}>&times;</button>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{padding:"20px"}}>
                                    <form className="notify_prod_form" id="forgot-password-form" onSubmit={this.handleOnNotifyProductSubmit}>
                                        <div className="col-xs-12">
                                        <h4 style={{fontSize:"20.5px"}}>Notificar cuando el producto esté disponible</h4>
                                        <p>Suscríbase a este producto para recibir un correo electrónico una vez que esté disponible</p>
                                        </div>
                                        <div className="col-xs-12">
                                            <Form.Group controlId="formGroupCardNumber">
                                                <Form.Control type="hidden" name="notifytoproduct" value={notifytoproduct} />
                                                <Form.Control type="email" name="notifytoemail" value={notifytoemail} onChange={this.handleOnNotifyEmailInputChange} placeholder="Enter Your Email" className={'form-control' + (notifyProductSubmitted && !notifytoemail ? ' has-error' : '')} maxLength="60"/>
                                                <span style={{color: "red"}}>{this.state.errors["notifytoemail"]}</span>
                                                <span style={{color: "green"}}>{this.state.notifyApiSuccessResponse}</span>
                                            </Form.Group>
                                        </div>
                                        
                                        <div className="col-xs-12 signup-btn-wrap">
                                            <button className="btn" value="Forgot Password" type="submit">
                                                <i className={`fa fa-refresh fa-spin ${ this.state.notifyProdLoader }`} style={{fontSize:"17px"}}></i> Notificarme
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Modal.Body>       
                    </Modal>
                    </>
                )

            }else{

                return(
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" >	
                        <h3>No Product Found.</h3>
                        </div>
                    </div>
                )

            }

        }else{
           
            return(
                <div className="row">
                    {Array(6).fill().map((item, index) => (
                        <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12" key={index}>
                            <div className="deal-link-item" key={index}>
                                <Skeleton height={200} width={`85%`} style={{marginBottom:'20px'}} />
                                <Skeleton height={30} width={90} style={{marginBottom:'7px'}} />
                                <Skeleton width={180} style={{marginBottom:'7px'}} />
                                <Skeleton width={200} style={{marginBottom:'7px'}} />
                                <Skeleton height={35} width={225} />
                            </div>
                        </div>
                    ))}
                </div>
            )

        }
    }
    
}

function mapStateToProps(state) {
    //console.log(state.cart.cartData);
    localStorage.setItem("cartData", JSON.stringify(state.cart.cartData))
    return { 
        isLoggedIn: state.user.isLoggedIn,
        loggedInUserEmail: (typeof(state.user.user) === 'undefined') ? "" :  state.user.user.email,
        isError: state.user.isError,
        errorMessage: state.user.errorMessage,
        cartData: state.cart.cartData,
        filteredCategoryProducts: state.filter.filteredProducts,
        isFilterProductsLoaded: state.filter.isFilterProductsLoaded,
        isCartAddError: state.cart.isCartAddError,
        cartErrorMessage: state.cart.cartErrorMessage,
    }
}

const MapDispachToProps = dispatch => ({
    addToCart: () => dispatch(addToCart())
})

const actionCreators = {
    addToCart:addToCart2,
    resetFilter: resetFilter,
    setBrandOptions: setBrandOptions,
    setProductsById: setProductsById
};

const ProductRightCategoryList = connect(
    mapStateToProps,
    actionCreators
)(ProductRightCategoryListComp)

export default withRouter(ProductRightCategoryList);