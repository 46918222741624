// utils/checkAuthToken.js
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import API_BASE_URL from '../config/api';

const checkAuthToken = async (token) => {
    
    const apiCheckTokenUrl = `${API_BASE_URL}/check-token`;
    try {

        const response = await axios.get(apiCheckTokenUrl, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          return response.status === 200;

        /* const request = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            }
        };

        const response = fetch(apiCheckTokenUrl, request)
                        .then(res => res.json())
                        .then((result) => {
                            console.log(result);
                            return result;
                        });
        return response.code === 200; */  // Token is valid if the server responds with 200 OK
    } catch (error) {
        console.error(error);
        if (error.response && error.response.status === 401) {
        return false;  // Token is invalid or expired
        }
        throw error;  // Other errors (network issues, etc.)
    }
};

export default checkAuthToken;
