import React from "react";
import { Link, Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import { Modal, Button } from 'react-bootstrap';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { redirectTo } from '../Header/redirecturl';
import { connect } from 'react-redux';
import API_BASE_URL from "../../config/api";

class TclBrandListingSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isProductError: null,
            isProductsLoaded : false,
            smartTvList: [],
            airconditionList: []
        }

    }

    handleOnProductRedirect(productId){
        return redirectTo("/producto/"+productId);
    }

    componentDidMount() {
        //const apiUrl = 'https://www.evisionstore.com/api/product/list-brandshop-products.php';
        const apiUrl = `${API_BASE_URL}/brandshop-products`;
        let params = this.props.match.params;
        let brandName = params.brandName;

        let requestOptions = {
            method: 'POST',
            body: JSON.stringify({"brand_name": brandName})
        };
        
        fetch(apiUrl, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    smarttvCategory: result.data.smarttv_category,
                    smartTvList: result.data.smarttv_list,
                    airconditionCategory: result.data.aircondition_category,
                    airconditionList: result.data.aircondition_list,
                    isProductsLoaded: true
                });
            },
            (error) => {
                this.setState({ isProductError: error });
            }
        )
    }

    componentDidUpdate(prevProps, prevState) {
        // only update chart if the data has changed
        if (prevProps.isLoggedIn !== this.props.isLoggedIn) {
            const isLoggedIn = this.props.isLoggedIn;
        }
    }

    render() {
        const { isProductsLoaded, smarttvCategory, smartTvList, airconditionCategory, airconditionList } = this.state;
     
        return (
            <section className="dashboard-container">
                <div className="section">
                    <div className="w-100 flex justify_between">
                        <div className="col1 pt-0">
                            <figure className="w-100 text-center">
                                <Link to="/fabricantes/tcl">
                                    <picture>
                                        <img src="/images/tcl/tcl_banner.jpg" alt="TCL" />
                                    </picture>
                                </Link>
                            </figure>
                        </div>
                        <div className="col1">
                            <figure className="w-100 text-center">
                                <Link to="/categorias/televisores/tv-y-smarttv?brand=tcl">
                                    <picture>
                                        <img src="/images/tcl/google_tv.jpg" alt="TCL Google TV" />
                                    </picture>
                                </Link>
                            </figure>
                        </div>

                        {(() => {
                        if (isProductsLoaded){
                            if (smartTvList.length > 0){
                                return ( 
                                <div className="container">
                                <div className="row">
                                    <div className="sub-cat-area">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div className="product-title"><h2>{smarttvCategory}</h2></div>
                                        </div>
                                        {smartTvList.map((smarttv, index) => (
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12" key={index}>
                                            <div className="sub-product-link-item">
                                            {(() => {
                                                let modelNumber = smarttv.modelo;
                                                let checkSlash = "/";
                                                let setModelNumber;
                                                if(modelNumber.indexOf(checkSlash) !== -1){
                                                    setModelNumber = smarttv.modeloch;
                                                }else{
                                                    setModelNumber = smarttv.modelo;
                                                }
                                                return (
                                                <>
                                                    <Link to="#" onClick={() => this.handleOnProductRedirect(setModelNumber)}>
                                                        <figure><img src={smarttv.product_image} alt="" /></figure>
                                                        <div className="heading-wrap">
                                                            <h3>{smarttv.product_name}</h3>
                                                        </div>
                                                    </Link>
                                                    <p style={{marginTop:'12px',fontSize:'14px',float:'left'}}>{smarttv.short_description}</p>
                                                    <button style={{width:'100%',fontSize:'16px',padding:'8px',background:'#20bced',color:'#fff',borderRadius:'3px',marginBottom:'10px'}} onClick={() => this.handleOnProductRedirect(setModelNumber)}>Ver Producto <i className="fa fa-long-arrow-right" aria-hidden="true"></i></button>
                                                </>
                                                )
                                            })()}
                                            </div>
                                        </div>
                                        ))}     
                                    </div>  
                                </div>
                                </div>
                                )
                            } else {
                                return null;
                            }
                        }else{
                            return null;
                        }
                        })()}

                        <div className="col1">
                            <figure className="w-100 text-center">
                                <Link to="/categorias/linea-blanca/aire-acondicionado?brand=hisense">
                                    <picture>
                                        <img src="/images/tcl/big_screen.jpg" alt="TCL" />
                                    </picture>
                                </Link>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>
        );
       
    }
}

export default withRouter(TclBrandListingSection);