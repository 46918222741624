import React, {Component} from 'react';
import BannerSection from './BannerSection';
import BreadcrumbSection from './BreadcrumbSection';
import HisenseBrandListingSection from './HisenseBrandListingSection';
import OsterBrandListingSection from './OsterBrandListingSection';
import LgBrandListingSection from './LgBrandListingSection';
import SamsungBrandListingSection from './SamsungBrandListingSection';
import SankeyBrandListingSection from './SankeyBrandListingSection';
import DrijaBrandListingSection from './DrijaBrandListingSection';
import CanonBrandListingSection from './CanonBrandListingSection';
import FrigidaireBrandListingSection from './FrigidaireBrandListingSection';
import TclBrandListingSection from './TclBrandListingSection';

class BrandShopPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentBrandName : false
        }
    }
    componentDidMount(){
        let params = this.props.match.params;
        let brandName = params.brandName;
        this.setState({
            currentBrandName: brandName
        });

        let allowedBrands = ['hisense', 'oster', 'samsung', 'sankey', 'drija', 'canon', 'frigidaire', 'lg', 'tcl'];
        if(allowedBrands.indexOf(brandName) === -1) {
            this.props.history.push({
                pathname: '/search/' + brandName
            });
        }
        window.Intercom('update', {
            "hide_default_launcher": true
        });
    }
    
    render(){
        const { currentBrandName } = this.state;

        if(currentBrandName && currentBrandName === 'hisense') {
            return (
                <div className="wraper">
                    <HisenseBrandListingSection />
                </div>
            )
        } else if(currentBrandName && currentBrandName === 'tcl') {
            return (
                <div className="wraper">
                    <TclBrandListingSection />
                </div>
            )
        } else if(currentBrandName && currentBrandName === 'oster') {
            return (
                <div className="wraper">
                    <OsterBrandListingSection />
                </div>
            )
        } else if(currentBrandName && currentBrandName === 'samsung') {
            return (
                <div className="wraper">
                    <SamsungBrandListingSection />
                </div>
            )
        } else if(currentBrandName && currentBrandName === 'sankey') {
            return (
                <div className="wraper">
                    <SankeyBrandListingSection />
                </div>
            )
        } else if(currentBrandName && currentBrandName === 'drija') {
            return (
                <div className="wraper">
                    <DrijaBrandListingSection />
                </div>
            )
        } else if(currentBrandName && currentBrandName === 'canon') {
            return (
                <div className="wraper">
                    <CanonBrandListingSection />
                </div>
            )
        } else if(currentBrandName && currentBrandName === 'frigidaire') {
            return (
                <div className="wraper">
                    <FrigidaireBrandListingSection />
                </div>
            )
        } else {
            return (
                <div className="wraper">
                    <LgBrandListingSection />
                </div>
            )
        }
    }
}

export default BrandShopPage;