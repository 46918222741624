import React, {Component} from 'react';
import { Link, Redirect } from "react-router-dom";
import ProfileTopSection from "./ProfileTopSection";
import ProfileDesktopLeftLinkSection from "./ProfileDesktopLeftLinkSection";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import API_BASE_URL from '../../config/api';
import checkAuthToken from '../../utils/checkAuthToken';

const TITLE = 'Mi tablero';

class Profile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: '', errors: {},
            sentDeleteRequest: false,
            sentDeleteRequestMessage: '',
            sentDeleteMessage: ''
        }
    }
    async componentDidMount(){
        const isValid = await checkAuthToken(localStorage.getItem('token'));
        
        if (!isValid) {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            this.props.history.push('/');
        }
    }
    deleteAccountRequest(){
        const user = JSON.parse(localStorage.getItem('user'));
        const email = user ? user.email : null;

        const apiUserDeleteUrl = `${API_BASE_URL}/user/delete-request`;

        const requestOptionsForDeleteRequest = {
            method: 'POST',
            body: JSON.stringify({"email_id": email}),
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            }
        };

        fetch(apiUserDeleteUrl, requestOptionsForDeleteRequest)
        .then(res => res.json())
        .then((result) => {
            if (result.status === true) {
                this.setState({
                    sentDeleteRequest: true,
                    sentDeleteRequestMessage: result.message
                })
            }
        });
    }

    deleteAccount(){
        const user = JSON.parse(localStorage.getItem('user'));
        const email = user ? user.email : null;
        const authCode = document.getElementById('authCodeInput').value;
        if (authCode){
            const apiAccountDeleteUrl = `${API_BASE_URL}/user/delete-account`;
            

            const requestOptionsForDeleteAccount = {
                method: 'POST',
                body: JSON.stringify({"email_id": email, 'auth_key': authCode}),
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            };
    
            fetch(apiAccountDeleteUrl, requestOptionsForDeleteAccount)
            .then(res => res.json())
            .then((result) => {
                if (result.status === true) {
                    this.setState({
                        sentDeleteRequest: false,
                        sentDeleteMessage: result.message
                    });

                    localStorage.removeItem('token');
                    localStorage.removeItem('user');
                    this.props.history.push('/');
                    window.location.reload(true);
                } else {
                    this.setState({
                        sentDeleteMessage: result.error
                    });
                }
            });
        }
    }

    render(){
        const {isLoggedIn, firstName, lastName} = this.props;
        if (!isLoggedIn) {
            return <Redirect to='/login' />;
        }

        return(
            <>
            <Helmet>
                <title>{ TITLE }</title>
            </Helmet>
            <ProfileTopSection />
            <section className="dashboard-container">
                <div className="container p-md-0">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="dasboard-title">Mi cuenta</h1>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12  desktop">		
                            <nav className="navigation">
                                {/* <h2>Mi cuenta</h2> */}
                                <ProfileDesktopLeftLinkSection />
                            </nav>
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                            <div className="row dashboard-right-panel">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 border-top-dashboard">
                                    <h3 className="tabname">Mi Tablero</h3>
                                    <p className="name-bottom-margin">HOLA, {firstName} {lastName}</p>
                                    <p>Desde el Panel de control de Mi cuenta, puede ver una instantánea de la actividad reciente de su cuenta y actualizar la información de su cuenta. Seleccione un enlace a continuación para ver o editar información.</p>
                                </div>

                                <div className="col-lg-12 clearfix">
                                    <ul className="pager" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        {/* <li className="previous pull-right"><Link to="/"> <i className="fa fa-home" /> Ir a la tienda </Link>
                                        </li> */}
                                                                        
                                        <li className="dash-button">
                                            <Link to="/profile" className="btn">
                                                Volver a Mi Cuenta
                                                <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/footer-arrow.svg`} alt="arrow" />
                                            </Link>
                                        </li>
                                        <li className="dash-button">
                                            <button className='btn' onClick={(e) => {
                                                e.preventDefault();
                                                this.deleteAccountRequest()
                                            }}
                                                >
                                                Solicitar eliminar cuenta
                                                <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/footer-arrow.svg`} alt="arrow" />
                                            </button>
                                        </li>
                                        {this.state.sentDeleteRequest && (
                                            <>
                                            <span>{this.state.sentDeleteRequestMessage}</span>
                                            <li className="dash-button">
                                                <input type="text" name="auth_code" id="authCodeInput" />
                                                <button className='btn' onClick={(e) => {
                                                    e.preventDefault();
                                                    this.deleteAccount()
                                                }}
                                                    >
                                                    Eliminar Cuenta
                                                    <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/footer-arrow.svg`} alt="arrow" />
                                                </button>
                                            </li>
                                            </>
                                        )}
                                        <span>{this.state.sentDeleteMessage}</span>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="line-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <hr></hr>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    isLoggedIn: state.user.isLoggedIn,
    firstName: state.user.user.first_name,
    lastName: state.user.user.last_name,
    cartItems: state.cart.cartdata
    //return isLoggedIn;
})

const ProfileComp = connect(
    mapStateToProps
)(Profile)

export default ProfileComp;