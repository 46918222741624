import React from "react";
import { Link, Redirect } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';
import UpsellSlider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { connect } from 'react-redux';
import { updateCart, removeCart } from '../../Services/Actions/cart.actions';
import { login2, signup } from '../../Services/Actions/user.actions';
import { allowGuestCheckout } from '../../Services/Actions/checkout.actions';
import { Helmet } from 'react-helmet';
import API_BASE_URL from '../../config/api';

function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
        <div
        className={className}
        onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
        <div
        className={className}
        onClick={onClick}
        />
    );
}

class CartListingSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cartProducts: this.props.cartProducts,
            cartProductsForTracking: [], criteoTrackingProducts: [],
            upsellProducts: [], isUpsellProductsLoaded: false,
            updatedCartProductQuan: [],
            username: '', password: '',
            firstname: '', lastname: '', email: '', telephone: '', fulltelephonenumber:'', regpassword: '',
            forgotpassemail: '',
            errors: {}, forgotPassLoader: 'hidden', registrationLoader: 'hidden',
            showModal: false,
            submitted: false, apiErrorMessage: '', apiSuccessMessage: '',
            loginSubmitted: false, registrationSubmitted: false, forgotPasswordSubmitted: false, forgotPasswordApiSuccessResponse: '', forgotPasswordApiErrorResponse: '',
            loginCheck: false,
            loginFormShowHide: "", registrationFormShowHide: "hidden", forgotPasswordShowHide: "hidden",
            showUpdateCartMsgModal: false, isCartUpdateError: false, cartErrorMessage: ''
        }

        this.handleOnLoginInputChange = this.handleOnLoginInputChange.bind(this);
        this.handleOnRegistrationInputChange = this.handleOnRegistrationInputChange.bind(this);
        this.handleOnLoginSubmit = this.handleOnLoginSubmit.bind(this);
        this.handleOnRegistrationSubmit = this.handleOnRegistrationSubmit.bind(this);
        this.handleOnForgotPasswordSubmit = this.handleOnForgotPasswordSubmit.bind(this);
        this.handleOnQuantityUpdate = this.handleOnQuantityUpdate.bind(this);
        //this.handleOnLoginCheckToGoCheckout = this.handleOnLoginCheckToGoCheckout.bind(this);
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.cartUpdatePopupResOpen = this.cartUpdatePopupResOpen.bind(this);
        this.cartUpdatePopupResClose = this.cartUpdatePopupResClose.bind(this);
        this.handleOnRegistrationTelInputChange = this.handleOnRegistrationTelInputChange.bind(this);
        //console.log(this.state.cartProducts)
    }

    componentDidMount() {
        let customer_id = this.props.customerId;

        //const apiUrl = 'https://www.evisionstore.com/api/product/cart-react.php';
        const apiUrl = `${API_BASE_URL}/cart`;
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({"customer_id": customer_id})
        };
        fetch(apiUrl, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                //console.log(result)
                if(result.code === 200){
                    this.setState({
                        cartProductsForTracking: result.data.cart_items,
                        upsellProducts: result.data.upsell_products,
                        isUpsellProductsLoaded: true
                    },() => {
                        if(this.state.cartProductsForTracking.length !== 0){
                            let l = (this.state.cartProductsForTracking.length - 1);
                            let s = "";
                            this.state.cartProductsForTracking.map((cartProductArr, index) => (
                                index === l
                                ? s += "{id:'" + cartProductArr.id + "', price:" + cartProductArr.price + ", quantity:" + cartProductArr.quantity + "}"
                                : s += "{id:'" + cartProductArr.id + "', price:" + cartProductArr.price + ", quantity:" + cartProductArr.quantity + "},"
                            ))  
                            this.setState({criteoTrackingProducts: s});
                        }
                    });
                }
            },
            (error) => {
                this.setState({ isUpsellProductsLoaded: error });
            }
        )
    }

    componentDidUpdate(prevProps, prevState) {
        // only update chart if the data has changed
        if (prevProps.isLoggedIn !== this.props.isLoggedIn) {
            const isLoggedIn = this.props.isLoggedIn;
            if (isLoggedIn) {
                this.setState({
                    username: '',
                    password: '' 
                });
                
                setTimeout(()=> 
                    this.close(),
                2000);
            }
        }
    }

    handleOnGuestCheckoutRedirect(){
        this.props.allowGuestCheckout();
    }

    handleOnRegistrationTelInputChange(isValid, newNumber, countryData, fullNumber, isExtension) {
        this.setState({telephone: newNumber });
        this.setState({fulltelephonenumber: fullNumber});
    }

    handleOnLoginInputChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleOnRegistrationInputChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleLoginFormValidation(){
        let username = this.state.username;
        let password = this.state.password;
        let errors = {};
        let loginFormIsValid = true;

        if(!username){
           loginFormIsValid = false;
           errors["username"] = "Email can not be empty";
        }
    
        if(typeof username !== "undefined"){
           let lastAtPos = username.lastIndexOf('@');
           let lastDotPos = username.lastIndexOf('.');
    
           if (!(lastAtPos < lastDotPos && lastAtPos > 0 && username.indexOf('@@') === -1 && lastDotPos > 2 && (username.length - lastDotPos) > 2)) {
              loginFormIsValid = false;
              errors["username"] = "Email is not valid";
            }
        }  

        if(!password){
            loginFormIsValid = false;
            errors["password"] = "Password can not be empty";
        }
    
       this.setState({errors: errors});
       return loginFormIsValid;
    }

    handleOnLoginSubmit(e){
        e.preventDefault();
        this.setState({ loginSubmitted: true });

        if(this.handleLoginFormValidation()){
            const username = this.state.username;
            const password = this.state.password;
            const cartItems = this.props.cartItems;
            
            this.props.logIn(username, password, cartItems);
        }
    }

    handleRegistrationFormValidation(){
        let firstname = this.state.firstname;
        let lastname = this.state.lastname;
        let email = this.state.email;
        let telephone = this.state.telephone;
        let regpassword = this.state.regpassword;
        let errors = {};
        let registrationFormIsValid = true;

        if(!firstname){
            registrationFormIsValid = false;
            errors["firstname"] = "Please enter your first name";
        }

        if(!lastname){
            registrationFormIsValid = false;
            errors["lastname"] = "Please enter your last name";
        }

        if(!telephone){
            registrationFormIsValid = false;
            errors["telephone"] = "Please enter your phone number.";
        } else{
            if(telephone.length < 6 || telephone.length > 15){
                registrationFormIsValid = false;
                errors["telephone"] = "Please enter your valid phone number.";
            }
        }
    
        if(typeof email !== "undefined"){
           let lastAtPos = email.lastIndexOf('@');
           let lastDotPos = email.lastIndexOf('.');
    
           if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') === -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
              registrationFormIsValid = false;
              errors["email"] = "Email id is not valid";
            }
        } else{
            registrationFormIsValid = false;
            errors["email"] = "Please enter your email id.";
        }  

        if(!regpassword){
            registrationFormIsValid = false;
            errors["regpassword"] = "Password can not be empty";
        }else{
            if(regpassword.length < 3){
                registrationFormIsValid = false;
                errors["regpassword"] = "Please enter minimum 4 characters password.";
            }
        }
    
       this.setState({errors: errors});
       return registrationFormIsValid;
    }

    handleOnRegistrationSubmit(e){
        e.preventDefault();
        this.setState({ registrationSubmitted: true });

        if(this.handleRegistrationFormValidation()){
            this.setState({ registrationLoader: "", loginFormButtonDisabled: true });
            const first_name = this.state.firstname;
            const last_name = this.state.lastname;
            const email = this.state.email;
            const password = this.state.regpassword;
            //const telephone = this.state.telephone;
            const fulltelephonenumber = this.state.fulltelephonenumber;

            this.props.signup(first_name, last_name, email, password, fulltelephonenumber);

            setTimeout(()=> 
                this.setState({
                    loginCheck: this.props.isLoggedIn 
                },() => {
                    if(this.state.loginCheck){
                        this.setState({apiSuccessMessage: 'Your account has been successfully registered on Evision.', loginFormButtonDisabled: false, registrationLoader: "hidden"});
                        setTimeout(()=> 
                            this.close(),
                        2000);
                    }
                    if(this.props.isSignupError){
                        this.setState({apiErrorMessage: this.props.signupErrorMessage, loginFormButtonDisabled: false, registrationLoader: "hidden"})
                    }
                }),
            2000);
        }
    }

    handleForgotPassFormValidation(){
        let forgotpassemail = this.state.forgotpassemail;
        let errors = {};
        let forgotPasswordFormIsValid = true;
    
        if(typeof forgotpassemail !== "undefined"){
           let lastAtPos = forgotpassemail.lastIndexOf('@');
           let lastDotPos = forgotpassemail.lastIndexOf('.');
    
           if (!(lastAtPos < lastDotPos && lastAtPos > 0 && forgotpassemail.indexOf('@@') === -1 && lastDotPos > 2 && (forgotpassemail.length - lastDotPos) > 2)) {
              forgotPasswordFormIsValid = false;
              errors["forgotpassemail"] = "Your registered email id is not valid";
            }
        } else {
            forgotPasswordFormIsValid = false;
            errors["forgotpassemail"] = "Please enter your registered email id";
        } 
    
       this.setState({errors: errors});
       return forgotPasswordFormIsValid;
    }

    handleOnForgotPasswordSubmit(e){
        e.preventDefault();
        this.setState({ forgotPasswordSubmitted: true });

        if(this.handleForgotPassFormValidation()){
            this.setState({ forgotPassLoader: "", loginFormButtonDisabled: true });
            let forgotpassemail = this.state.forgotpassemail;

            //const apiUrl = 'https://www.evisionstore.com/api/user/forgotpassword.php';
            const apiUrl = `${API_BASE_URL}/forgot-password`;
        
            fetch(apiUrl, {
                method: 'POST',
                body: JSON.stringify({
                    "email": forgotpassemail
                })
            })
            .then(res => res.json())
            .then(
                (result) => {
                    if(result.code === 200){
                        this.setState({
                            forgotPasswordApiSuccessResponse: result.message,
                            forgotPassLoader: "hidden",
                            loginFormButtonDisabled: false
                        });
                    }else{
                        this.setState({
                            forgotPasswordApiErrorResponse: result.message,
                            forgotPassLoader: "hidden",
                            loginFormButtonDisabled: false
                        });
                    }
                },
                (error) => {
                    this.setState({ error, forgotPassLoader: "hidden", loginFormButtonDisabled: false });
                }
            )
        }
    }

    handleOnFormChange(buttonParam) {
        if(buttonParam==="registration") {
            this.setState({
                loginFormShowHide: 'hidden',
                registrationFormShowHide: '',
                forgotPasswordShowHide: 'hidden'
            });
        } else if(buttonParam==="login") {
            this.setState({
                loginFormShowHide: '',
                registrationFormShowHide: 'hidden',
                forgotPasswordShowHide: 'hidden'
            });
        } else if(buttonParam==="forgotpassword"){
            this.setState({
                loginFormShowHide: 'hidden',
                registrationFormShowHide: 'hidden',
                forgotPasswordShowHide: ''
            });
        }
    }

    handleOnQuanChange(prodId, prodName, prodImage, prodPrice, index, inputval, prodSelectedVariationId){
        const prodQuantity = inputval;
        //document.getElementById("quan_"+index).value = prodQuantity;
        //this.props.updateCart(prodId, prodName, prodImage, prodPrice, prodQuantity);

        if(prodQuantity > 0){
            this.props.updateCart(prodId, prodName, prodImage, prodPrice, prodQuantity, prodSelectedVariationId);

            setTimeout(()=> 
                this.setState({
                    isCartUpdateError: this.props.isCartUpdateError 
                },() => {
                    if(this.state.isCartUpdateError){
                        this.setState({cartErrorMessage: this.props.cartErrorMessage});
                        this.cartUpdatePopupResOpen();
                        setTimeout(()=> 
                            this.cartUpdatePopupResClose(),
                        5000);
                    } else {
                        this.setState({cartErrorMessage: ''});
                    }
                }),
            3000);
        }
    }

    handleOnQuantityUpdate(type, prodId, prodName, prodImage, prodPrice, index){
        var prodQuantity = parseInt(document.getElementById("quan_"+index).value);
        //console.log(prodQuantity)
        if(type==="increase"){
            prodQuantity = (prodQuantity + 1);
            //let addedItem = this.state.updatedCartProductQuan.find(item=> item.prodId === prodId)
            let existedItem = this.state.updatedCartProductQuan.find(item=> prodId === item.prodId)
            
            if(existedItem){
                let updatedCartProductQuan = [...this.state.updatedCartProductQuan];
                updatedCartProductQuan[index] = { prodId: updatedCartProductQuan[index].prodId, prodQuantity: prodQuantity };

                this.setState({ updatedCartProductQuan: updatedCartProductQuan }, () => {
                    document.getElementById("quan_"+index).value = prodQuantity;
                    //console.log("Ex: "+prodQuantity);
                }); 
            }else{
                let incrementProdQuanArr = {};
                incrementProdQuanArr["prodId"] = prodId;
                incrementProdQuanArr["prodQuantity"] = prodQuantity;

                this.setState({ updatedCartProductQuan: [ ...this.state.updatedCartProductQuan, incrementProdQuanArr ] }, () => {
                    document.getElementById("quan_"+index).value = prodQuantity;
                    //console.log("Nw: "+prodQuantity);
                }); 
            }
        } else if(type==="decrease"){
            prodQuantity = (prodQuantity - 1);

            if(prodQuantity!==0){
                let addedItem = this.state.updatedCartProductQuan.find(item=> item.prodId === prodId)
                let existedItem = this.state.updatedCartProductQuan.find(item=> prodId === item.prodId)

                if(existedItem){
                    addedItem.prodQuantity = prodQuantity;

                    this.setState({ updatedCartProductQuan: [...this.state.updatedCartProductQuan, addedItem] }, () => {
                        document.getElementById("quan_"+index).value = prodQuantity;
                    }); 
                }else{
                    let decrementProdQuanArr = {};
                    decrementProdQuanArr["prodId"] = prodId;
                    decrementProdQuanArr["prodQuantity"] = prodQuantity;
                    
                    this.setState({ updatedCartProductQuan: [ ...this.state.updatedCartProductQuan, decrementProdQuanArr ] }, () => {
                        document.getElementById("quan_"+index).value = prodQuantity;
                    }); 
                }
            }
        } else{
            //console.log(prodId);
        }

    }

    handleOnQuantityUpdate2(type, prodId, prodName, prodImage, prodPrice, index, prodSelectedVariationId){
        let prodQuantity = parseInt(document.getElementById("quan_"+index).value);
        if(type==="increase"){
            prodQuantity = (prodQuantity + 1);
            //console.log(prodQuantity)
        }else{
            prodQuantity = (prodQuantity - 1);
        }
        if(prodQuantity!==0){
            this.props.updateCart(prodId, prodName, prodImage, prodPrice, prodQuantity, prodSelectedVariationId);

            setTimeout(()=> 
                this.setState({
                    isCartUpdateError: this.props.isCartUpdateError 
                },() => {
                    if(this.state.isCartUpdateError){
                        this.setState({cartErrorMessage: this.props.cartErrorMessage});
                        this.cartUpdatePopupResOpen();
                        setTimeout(()=> 
                            this.cartUpdatePopupResClose(),
                        5000);
                    } else {
                        this.setState({cartErrorMessage: ''});
                    }
                }),
            3000);
        }
    }

    handleOnRemoveCart(prodId, index, prodName, prodPrice, prodVariationId){
        this.props.removeCart(prodId, index, prodVariationId);

        const prodNameArr = prodName.split(' ');
        let marca = prodNameArr[0];
        let modelo = prodNameArr[1];

        window.ga("ec:addProduct", {
            "id": modelo,
            "name": prodName,
            "price": prodPrice,
            "brand": marca,
            "position": 0,
            "quantity": 1
        });
        window.ga("ec:setAction", "remove");
        window.ga("send", "event", "Cart Page", "click", "removeFromCart");
    }

    fbPixelFire(){
        window.fbq('track', 'InitiateCheckout');
    }

    open() {
        this.setState({showModal: true});
    }
    
    close() {
        this.setState({showModal: false});
    }

    cartUpdatePopupResOpen() {
        this.setState({showUpdateCartMsgModal: true});
    }
    
    cartUpdatePopupResClose() {
        this.setState({showUpdateCartMsgModal: false});
    }

    financial = (x) => Number.parseFloat(x).toFixed(2);

    render() {
        const {isLoggedIn, cartProducts} = this.props;
        const { username, password, firstname, lastname, email, telephone, regpassword, loginSubmitted, registrationSubmitted, forgotPasswordSubmitted, forgotpassemail, loginCheck, upsellProducts } = this.state;
        //console.log(cartProducts)
        let totalProductPrice = 0;
        let totalTaxAmount = 0;

        const upsellproductslidersettings = {
            //dots: true,
            infinite: true,
            //autoplay: true,
            speed: 500,
            //rows: 1,
            //slidesPerRow: 2,
            slidesToShow: 4,
            slidesToScroll: 1,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />
        };

        // if(loginCheck){
        //     return <Redirect to={{ pathname: "/checkout", state: { referer: this.props.location } }} />;
        // }
       
        const linkToCheckoutPageAfterLogin = (
            <Link to="/checkout" onClick={this.fbPixelFire} className="btn btn-primary btn-lg btn-block " title="checkout"> 
                Proceder a Pagar
                <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/home-slide-arrow-next.svg`} alt="arrow" /> 
            </Link>
        );
    
        const linkToCheckoutPageBeforeLogin = (
            <Link to="#" onClick={this.open} className="btn btn-primary btn-lg btn-block " title="checkout"> 
                Proceder a Pagar
                <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/home-slide-arrow-next.svg`} alt="arrow" /> 
            </Link>
        );

        const apiError = {
            color: 'red', fontSize: '16px', background: '#fff9b0', padding: '0px 5px 2px 5px', fontWeight: 'normal', textAlign: 'center', border: '1px solid red', borderRadius: '3px'
        }

        const apiSuccess = {
            color: '#316403', fontSize: '16px', background: '#cbffb0', padding: '0px 5px 2px 5px', fontWeight: 'normal', textAlign: 'center', border: '1px solid #178603', borderRadius: '3px'
        }
        

        if (cartProducts.length > 0) {
            for (var q = 0, pricelen = cartProducts.length; q < pricelen; q++) {
                let singleProdPrice = parseFloat(cartProducts[q]['prodPrice']);
                let singleProdQuan = parseInt(cartProducts[q]['prodQuantity']);
                totalProductPrice = (totalProductPrice + (singleProdPrice * singleProdQuan)); 
            }

            if (totalProductPrice > 0) {
                totalTaxAmount = (totalProductPrice * (7 / 100));
            }
        }

        if(cartProducts.length === 0){
            return (
                <section className="dashboard-container">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12" style={{textAlign:"center"}}>
                                <p>No tiene artículos en su carrito de compras. Haga clic aquí para seguir comprando.</p>
                                <p>
                                    <Link to="/"><i className="fa fa-chevron-left" /> Pagina Principal </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            );
        } else {
            return (
                <>
                <Helmet>
                    {/* Criteo Category / Listing dataLayer */}
                    <script>
                    {`
                        var dataLayer = dataLayer || [];
                        dataLayer.push({
                            event: 'crto_basketpage',
                            crto: {
                                email: "${this.props.userEmail}",
                                products: [${this.state.criteoTrackingProducts}]
                            }
                        });
                    `}
                    </script>
                    {/* END Criteo Category / Listing dataLayer */}

                    {/* Criteo GTM Tag */}
                    <script type="text/javascript" src="//static.criteo.net/js/ld/ld.js" async="true"></script>
                    <script>
                    {`
                        window.criteo_q = window.criteo_q || [];
                        window.criteo_q.push(
                        { event: "setAccount", account: 65991 },
                        { event: "setEmail", email: "${this.props.userEmail}" },
                        { event: "setSiteType", type: "d" },
                        { event: "viewBasket", item: [${this.state.criteoTrackingProducts}] }
                        );
                    `}
                    </script>
                    {/* End Criteo GTM Tag */}
                </Helmet>
                <section className="dashboard-container">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <h3 className="section-title-inner">Carrito de productos</h3>
                            </div>
                            {/* <div className="col-lg-9 col-md-9 col-sm-7 col-xs-12 col-xxs-12 text-center">
                            <h3 className="section-title-inner"><span><i className="fa fa-shopping-cart" aria-hidden="true" /> Carrito de productos </span></h3>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-5 rightSidebar col-xs-6 col-xxs-12 text-center-xs">
                                <p className="caps"> */}
                                    {/* <Link to="/"><i className="fa fa-chevron-left" /> De vuelta a las compras </Link> */}
                                {/* </p>
                            </div> */}
                        </div>
                        <div className="row">
                            <div className="col-lg-7 col-md-6 col-sm-12">
                                <div className="row userInfo">
                                    <div className="col-xs-12 col-sm-12">
                                        <div className="cartContent w100">
                                            <table className="d-none cartTable table-responsive" style={{padding: '0', width: '100%'}}>
                                                <tbody>
                                                    <tr className="CartProduct cartTableHeader" style={{lineHeight: 2}}>
                                                    <td style={{width: '15%'}}>PRODUCTO</td>
                                                    <td style={{width: '45%'}}>Detalles</td>
                                                    <td style={{width: '10%'}} className="delete">&nbsp;</td>
                                                    <td style={{width: '10%'}}>CANT.</td>
                                                    {/* <td style={{width: '10%'}}>Discount</td> */}
                                                    <td style={{width: '20%'}}>Total</td>
                                                    </tr>

                                                    {cartProducts.map((products, index) => (
                                                    /* let modelNumber = localState.modelo;
                                                    let checkSlash = "/";
                                                    let setModelNumber;
                                                    if (modelNumber.indexOf(checkSlash) !== -1) {
                                                        setModelNumber = localState.modeloch;
                                                    } else {
                                                        setModelNumber = localState.modelo;
                                                    } */
                                                    <tr className="CartProduct" key={index}>
                                                    <td className="CartProductThumb">
                                                        <div>
                                                            <Link to={`/producto/${products.prodModelo.replace(/\//g, '')}`}>
                                                                <img src={products.prodImage} alt={products.prodImage} />
                                                            </Link>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="CartDescription">
                                                        <h4><Link to={`/producto/${products.prodModelo.replace(/\//g, '')}`}>{products.prodName}</Link></h4>
                                                        {/* <span className="size">12 x 1.5 L</span> */}
                                                        <div className="price"><span>$ {this.financial(products.prodPrice)}</span></div>
                                                        </div>
                                                    </td>
                                                    <td className="delete">
                                                        <Link to="#" title="Delete" onClick={() => this.handleOnRemoveCart(products.prodId, index, products.prodName, products.prodPrice, products.prodSelectedVariationId)}> 
                                                            <i className="glyphicon glyphicon-trash fa-2x" />
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <div className="input-group bootstrap-touchspin">
                                                            <span className="input-group-btn">
                                                                <button style={{fontWeight:'600'}} onClick={() => this.handleOnQuantityUpdate2("decrease", products.prodId, products.prodName, products.prodImage, products.prodPrice, index, products.prodSelectedVariationId)} className="btn btn-link bootstrap-touchspin-down" type="button">-</button>
                                                            </span>
                                                            <span className="input-group-addon bootstrap-touchspin-prefix" ></span>
                                                            <input style={{width:'80px',textAlign:'center'}} id={`quan_${index}`} onChange={e => this.handleOnQuanChange(products.prodId, products.prodName, products.prodImage, products.prodPrice, index, e.target.value, products.prodSelectedVariationId)} value={products.prodQuantity} className="quanitySniper form-control" type="text" name="quanitySniper" />
                                                            <span className="input-group-addon bootstrap-touchspin-prefix" ></span>
                                                            <span className="input-group-btn">
                                                                <button style={{fontWeight:'600'}} onClick={() => this.handleOnQuantityUpdate2("increase", products.prodId, products.prodName, products.prodImage, products.prodPrice, index, products.prodSelectedVariationId)} className="btn btn-link bootstrap-touchspin-up" type="button">+</button>
                                                            </span>
                                                        </div>
                                                    </td>
                                                    {/* <td>0</td> */}
                                                    <td className="price">$ {this.financial(products.prodPrice * products.prodQuantity)}</td>
                                                    </tr>
                                                    ))}
                                                    
                                                </tbody>
                                            </table>
                                            {cartProducts.map((products, index) => (
                                                <ul className="cart-list" key={index}>
                                                    <li className="cart-item">
                                                        <div className="cart-thumb_image">
                                                            <Link to={`/producto/${products.prodModelo.replace(/\//g, '')}`}>
                                                                <img src={products.prodImage} alt={products.prodImage} />
                                                            </Link>
                                                        </div>
                                                        <div className="cart-product_description">
                                                            <div className="cart-product_info">
                                                                <div className="CartDescription">
                                                                    <h4><Link to={`/producto/${products.prodModelo.replace(/\//g, '')}`}>{products.prodName}</Link></h4>
                                                                    {/* <p>Canon eos rebel t7 ef-s 18-55mm is ii camera kit</p> */}
                                                                    {/* <span className="size">12 x 1.5 L</span> */}
                                                                    {/* <div className="price"><span>$ {this.financial(products.prodPrice)}</span></div> */}
                                                                </div>
                                                                <span className="modprice">$ {this.financial(products.prodPrice * products.prodQuantity)}</span>
                                                            </div>
                                                            <div className="cart-product_action">
                                                                <div className="input-group bootstrap-touchspin">
                                                                    <span className="input-group-btn">
                                                                        <button onClick={() => this.handleOnQuantityUpdate2("decrease", products.prodId, products.prodName, products.prodImage, products.prodPrice, index, products.prodSelectedVariationId)} className="btn btn-link bootstrap-touchspin-down" type="button">-</button>
                                                                    </span>
                                                                    <span className="input-group-addon bootstrap-touchspin-prefix" ></span>
                                                                    <input id={`quan_${index}`} onChange={e => this.handleOnQuanChange(products.prodId, products.prodName, products.prodImage, products.prodPrice, index, e.target.value, products.prodSelectedVariationId)} value={products.prodQuantity} className="quanitySniper form-control" type="text" name="quanitySniper" />
                                                                    <span className="input-group-addon bootstrap-touchspin-prefix" ></span>
                                                                    <span className="input-group-btn">
                                                                        <button onClick={() => this.handleOnQuantityUpdate2("increase", products.prodId, products.prodName, products.prodImage, products.prodPrice, index, products.prodSelectedVariationId)} className="btn btn-link bootstrap-touchspin-up" type="button">+</button>
                                                                    </span>
                                                                </div>
                                                                <div className="action-area">
                                                                    <span className="action-area-buttton">
                                                                        <Link to="#" title="Delete" onClick={() => this.handleOnRemoveCart(products.prodId, index, products.prodName, products.prodPrice, products.prodSelectedVariationId)}> 
                                                                            <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/cart-delete.svg`} alt="delete-icon" width="18" height="20" />
                                                                        </Link>
                                                                    </span>
                                                                    <span className="action-area-buttton">
                                                                        {/*- STATIC SECTION FOR Edit START -*/}
                                                                        {/* <a href="javascript:void(0)">
                                                                        <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/cart-edit.svg`} alt="delete-icon" width="18" height="20" />
                                                                        </a> */}
                                                                        {/*- STATIC SECTION FOR Edit ENDS -*/}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            ))}    
                                        </div>
                                        <div className="cartFooter w100">
                                            <div className="box-footer">
                                                <div className="pull-left">
                                                    <Link to="/" className="btn btn-primary"> 
                                                        <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/home-slide-arrow-prev.svg`} alt="arrow" /> 
                                                        Seguir comprando
                                                    </Link>
                                                </div>
                                                {/* <div className="pull-right">
                                                    <button type="submit" className="btn btn-success"><i className="fa fa-undo" /> 
                                                    &nbsp; Update cart
                                                    </button>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-6 col-sm-12 rightSidebar">
                                <div className="contentBox">
                                    <div className="w100 costDetails">
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className="table-block" id="order-detail-content">
                                                <div className="w100 cartMiniTable">
                                                    <table id="cart-summary" className="std table d-none">
                                                        <tbody>
                                                        <tr>
                                                            <td>Total parcial</td>
                                                            <td className="price">$ {this.financial(totalProductPrice)}</td>
                                                        </tr>
                                                        {/* <tr>
                                                            <td>Costo de envío</td>
                                                            <td className="price"><span className="success">Envío gratis!</span></td>
                                                        </tr> */}
                                                        <tr>
                                                            <td>ITBMS (7% Tax)</td>
                                                            <td className="price">$ {this.financial(totalTaxAmount)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Total</strong></td>
                                                            <td className=" site-color" id="total-price">
                                                                <strong>$ {this.financial(totalProductPrice + totalTaxAmount)}</strong>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                        <tbody>
                                                        </tbody>
                                                    </table>
                                                    <div className="recipt-box">
                                                        <h4>Resumen del pedido</h4>
                                                        <div className="recipt-row">
                                                            <span>Subtotal</span>
                                                            <span className="strong">${this.financial(totalProductPrice)}</span>
                                                        </div>
                                                        {/* <div className="recipt-row">
                                                            <span>Discount</span>
                                                            <span className="strong">$35.50</span>
                                                        </div> */}
                                                        <div className="recipt-row">
                                                            <span>ITBMS (7% Tax)</span>
                                                            <span className="strong">${this.financial(totalTaxAmount)}</span>
                                                        </div>
                                                        {/* <div className="recipt-row">
                                                            <span>Impuesto</span>
                                                            <span className="strong">$00.00</span>
                                                        </div>
                                                        <div className="recipt-row">
                                                            <span>Envío</span>
                                                            <span className="strong">Gratis</span>
                                                        </div> */}
                                                        <div className="recipt-row">
                                                            <span className="strong">Total</span>
                                                            <span className="strong">${this.financial(totalProductPrice + totalTaxAmount)}</span>
                                                        </div>
                                                        <div className="btn-area">
                                                            { isLoggedIn ? linkToCheckoutPageAfterLogin : linkToCheckoutPageBeforeLogin }
                                                            {/* <span>Estimated Delivery by 25 April, 2024</span> */}
                                                        </div>
                                                    </div>
                                                    {/* <div className="recipt-box">
                                                        <h4>Tiene un cupón ?</h4>
                                                        <div className="coupon">
                                                            <input type="text" placeholder="Código promocional" />
                                                            <button>Aplicar</button>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 d-none">
                                        { isLoggedIn ? linkToCheckoutPageAfterLogin : linkToCheckoutPageBeforeLogin }
                                        </div>
                                    </div>
                                    
                                </div>
                                
                            </div>
                        </div>
                        
                        {Array.isArray(upsellProducts) && upsellProducts.length !== 0 ? (
                            <div className="row" style={{marginTop:'30px'}}>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xxs-12">
                                    <h3 className="section-title-inner">Productos Recomendados</h3>
                                    {upsellProducts.length > 4 ? (
                                        <section className="upsellproducts slider">
                                            <UpsellSlider {...upsellproductslidersettings}>
                                            {upsellProducts.map((upproducts, index) => (
                                                <div className="slide" key={index}>
                                                    <div className="deal-link-item">
                                                        <Link to={`/producto/${upproducts.modelo}`} style={{textDecoration:'none', color:'#333'}}>
                                                            <figure>
                                                                <img src={upproducts.image_path} alt={upproducts.marca} />
                                                            </figure>
                                                            <div className="heading-wrap">
                                                                <div className="price">$ {upproducts.price}</div>
                                                                <h2>{upproducts.name}</h2>
                                                                <p>{upproducts.short_desc ? upproducts.short_desc : "..."}</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            ))}
                                            </UpsellSlider>
                                        </section>
                                    ) : (
                                        <section className="upsell-container">
                                            <div className="row">
                                            {upsellProducts.map((upproducts, index) => (
                                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12" key={index}>
                                                    <div className="deal-link-item">
                                                        <Link to={`/producto/${upproducts.modelo}`} style={{textDecoration:'none', color:'#333'}}>
                                                            <figure>
                                                                <img src={upproducts.image_path} alt={upproducts.marca} />
                                                            </figure>
                                                            <div className="heading-wrap">
                                                                <div className="price">$ {upproducts.price}</div>
                                                                <h2>{upproducts.name}</h2>
                                                                <p>{upproducts.short_desc ? upproducts.short_desc : "..."}</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            ))}
                                            </div>
                                        </section>
                                    )}           
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>

                    <Modal show={this.state.showModal} onHide={this.close} animation={true} size="md" aria-labelledby="example-modal-sizes-title-lg">                             
                        <Modal.Body>
                            <div className="popupbg"> 
                                <button type="button" className="close" onClick={this.close}>&times;</button>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 right-form-area" style={{padding:'0px 30px 20px 30px'}}>
                                    <div className="right-form-logo mobile-logo-login">
                                        <img src={`${process.env.PUBLIC_URL}/images/popup-logo.png`} title="logo" alt="logo" />
                                    </div>
                                    <h2> Iniciar sesion</h2>
                                    <h3> Obtenga acceso a sus cuentas y sus pedidos</h3>
                                    <div className={`form-wrap ${this.props.loginErrorMessage ? '' : 'hidden'} `}>
                                        <label style={apiError}>{this.props.loginErrorMessage}</label>
                                    </div>
                                    <div className={`form-wrap ${this.props.loginSuccessMessage ? '' : 'hidden'} `}>
                                        <label style={apiSuccess}>{this.props.loginSuccessMessage}</label>
                                    </div>
                                    <form className={`login_form ${ this.state.loginFormShowHide }`} id="login-form" onSubmit={this.handleOnLoginSubmit} >
                                        <div className="form-wrap">
                                            <div className="input-wrap">
                                            <span className="login-icon">
                                            <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/mail-icon.svg`} alt="Mail Icon" rel="noopener noreferrer" />
                                            </span>
                                            <input className={'input-text' + (loginSubmitted && !username ? ' has-error' : '')} type="email" name="username" value={username} onChange={this.handleOnLoginInputChange} placeholder="Enter Your Email" maxLength="70"/>
                                            </div>
                                            <span style={{color: "red"}}>{this.state.errors["username"]}</span>
                                        </div>
                                        <div className="form-wrap">
                                            <div className="input-wrap">
                                            <span className="login-icon">
                                            <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/password-icon.svg`} alt="Mail Icon" rel="noopener noreferrer" />
                                            </span>
                                            <input className={'input-text' + (loginSubmitted && !password ? ' has-error' : '')} type="password" name="password" value={password} onChange={this.handleOnLoginInputChange} maxLength="50" placeholder="Enter Your Password"/>
                                            </div>
                                            <span style={{color: "red"}}>{this.state.errors["password"]}</span>
                                        </div>
                                        <Link to="#" id="forgots" className="no-account" onClick={e => this.handleOnFormChange("forgotpassword")}>¿Olvidaste tu contraseña? </Link>
                                        <div className="signup-btn-wrap">
                                            <button className="btn" value="Login" id="logins" type="submit" disabled={this.props.loginFormButtonDisabled}>
                                                Iniciar sesion
                                                <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/home-slide-arrow-next.svg`} alt="arrow" /> 
                                            </button>
                                        </div>
                                        <div className="reg-btn-wrap text-center-middle">
                                            <button className="btn" type="button" onClick={e => this.handleOnFormChange("registration")} >Regístrate</button>
                                        </div>
                                    </form>

                                    <form className={`registration_form ${ this.state.registrationFormShowHide }`} id="registration-form" onSubmit={this.handleOnRegistrationSubmit}>
                                        <div className="form-wrap">
                                            <label>Primer Nombre</label>
                                            <input className={'input-text' + (registrationSubmitted && !firstname ? ' has-error' : '')} name="firstname" value={firstname} onChange={this.handleOnLoginInputChange} placeholder="Enter Your First Name" maxLength="70"/>
                                            <span style={{color: "red"}}>{this.state.errors["firstname"]}</span>
                                        </div>
                                        <div className="form-wrap">
                                            <label>Apellido</label>
                                            <input className={'input-text' + (registrationSubmitted && !lastname ? ' has-error' : '')} name="lastname" value={lastname} onChange={this.handleOnRegistrationInputChange} placeholder="Enter Your Last Name" maxLength="50"/>
                                            <span style={{color: "red"}}>{this.state.errors["lastname"]}</span>
                                        </div>
                                        <div className="form-wrap">
                                            <label>Correo electrónico</label>
                                            <input className={'input-text' + (registrationSubmitted && !email ? ' has-error' : '')} type="email" name="email" value={email} onChange={this.handleOnRegistrationInputChange} placeholder="Enter Your Email Id" maxLength="50"/>
                                            <span style={{color: "red"}}>{this.state.errors["email"]}</span>
                                        </div>
                                        <div className="form-wrap">
                                            <label>Teléfono</label>
                                            <IntlTelInput containerClassName="intl-tel-input" inputClassName={'input-text' + (registrationSubmitted && !telephone ? ' has-error' : '')} fieldName="telephone" fieldId="InputMobile" value={telephone} onPhoneNumberChange={(...args) =>{this.handleOnRegistrationTelInputChange(...args)}} placeholder="Enter Your Telephone Number" maxLength="15" style={{width:'100%'}} separateDialCode format={true}/>
                                            {/* <input className={'input-text' + (registrationSubmitted && !telephone ? ' has-error' : '')} name="telephone" value={telephone} onChange={this.handleOnRegistrationInputChange} placeholder="Enter Your Telephone Number" maxLength="50"/> */}
                                            <span style={{color: "red"}}>{this.state.errors["telephone"]}</span>
                                        </div>
                                        <div className="form-wrap">
                                            <label>Contraseña</label>
                                            <input className={'input-text' + (registrationSubmitted && !regpassword ? ' has-error' : '')} type="password" name="regpassword" value={regpassword} onChange={this.handleOnRegistrationInputChange} placeholder="Enter Your Password" maxLength="50"/>
                                            <span style={{color: "red"}}>{this.state.errors["regpassword"]}</span>
                                        </div>
                                        <Link to="#" id="forgots" className="no-account" onClick={e => this.handleOnFormChange("login")}>Usuario existente? Iniciar sesion </Link>
                                        <div className="reg-btn-wrap">
                                        <button className="btn" value="Registration" id="reg" type="submit" disabled={this.state.loginFormButtonDisabled}>
                                            <i className={`fa fa-refresh fa-spin ${ this.state.registrationLoader }`} style={{fontSize:"17px"}}></i> Regístrate
                                        </button>
                                        </div>
                                        <div className="signup-btn-wrap">
                                            <button className="btn" type="button" onClick={e => this.handleOnFormChange("login")}>Iniciar sesion</button>
                                        </div>
                                    </form>

                                    <form className={`forgot_pass_form ${ this.state.forgotPasswordShowHide }`} id="forgot-password-form" onSubmit={this.handleOnForgotPasswordSubmit}>
                                        <div className="form-wrap">
                                            <label>Correo electrónico</label>
                                            <input className={'input-text' + (forgotPasswordSubmitted && !forgotpassemail ? ' has-error' : '')} type="email" name="forgotpassemail" value={forgotpassemail} onChange={this.handleOnRegistrationInputChange} placeholder="Enter Your Email" maxLength="50"/>
                                            <span style={{color: "red"}}>{this.state.errors["forgotpassemail"]}</span>
                                            <span style={{color: "green"}}>{this.state.forgotPasswordApiSuccessResponse}</span>
                                            <span style={{color: "red"}}>{this.state.forgotPasswordApiErrorResponse}</span>
                                        </div>
                                        <Link to="#" id="forgots" className="no-account" onClick={e => this.handleOnFormChange("login")}> Atrás para iniciar sesión? </Link>
                                        <div className="signup-btn-wrap">
                                            <button className="btn" value="Forgot Password" type="submit" disabled={this.state.loginFormButtonDisabled}>
                                                <i className={`fa fa-refresh fa-spin ${ this.state.forgotPassLoader }`} style={{fontSize:"17px"}}></i> Enviar
                                            </button>
                                        </div>
                                    </form>

                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 continue-guest">
                                        <hr/>
                                        <h4 className="g-heading">No Quiero Registrarme</h4>
                                        <p className="g-text">You don't have to have an account to buy from us. You can checkout without creating an account. Just click the "Continuar Como Invitado" button.</p>
                                        <p className="g-paragraph">
                                            <Link to="/checkout" onClick={() => this.handleOnGuestCheckoutRedirect()} className="btn btn-success">
                                                Continuar Como Invitado
                                                <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/home-slide-arrow-next.svg`} alt="arrow" /> 
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>       
                    </Modal>
                    
                    <Modal show={this.state.showUpdateCartMsgModal} className="content-box" onHide={this.cartUpdatePopupResClose} animation={true}>                          
                        <Modal.Header closeButton>
                            <Modal.Title>Oops!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body><p className="text-padding">{this.state.cartErrorMessage}</p></Modal.Body>     
                    </Modal>
                </section>
                <div className="line-footer m-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                <hr></hr>
                            </div>
                        </div>
                    </div>
                </div>
                </>
            );
        }
    }
}

function mapStateToProps(state) {
    localStorage.setItem("cartData", JSON.stringify(state.cart.cartData))
    localStorage.setItem("isAllowGuestCheckout", state.checkout.isAllowGuestCheckout)
    return { 
        isCartUpdateError: state.cart.isCartUpdateError,
        cartErrorMessage: state.cart.cartErrorMessage,
        cartProducts: [...state.cart.cartData],
        isLoggedIn: state.user.isLoggedIn,
        cartItems: state.cart.cartData,
        cartCount: state.cart.cartCount,
        customerId: (typeof(state.user.user) === 'undefined') ? "" :  state.user.user.customer_id,
        userEmail: (typeof(state.user.user) === 'undefined') ? "" :  state.user.user.email,
        isError: state.user.isError,
        loginErrorMessage: state.user.loginErrorMessage,
        loginSuccessMessage: state.user.loginSuccessMessage,
        loginLoader: state.user.loginLoader,
        loginFormButtonDisabled: state.user.loginFormButtonDisabled,
        isSignupError: state.user.isSignupError,
        signupErrorMessage: state.user.signupErrorMessage
    }
}

const actionCreators = {
    updateCart: updateCart,
    removeCart: removeCart,
    logIn: login2,
    signup: signup,
    allowGuestCheckout: allowGuestCheckout
};

const CartListingComp = connect(
    mapStateToProps,
    actionCreators
)(CartListingSection)

export default CartListingComp;