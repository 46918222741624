import React, {Component} from 'react';
import { Link } from "react-router-dom";

export default class BrandShopLogosSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            subbanner: false,
        }
    }

    componentDidMount() {
        const apiUrl = 'https://www.evisionstore.com/api/home/subbanner.php';

        fetch(apiUrl)
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    subbanner: result
                });
            },
            (error) => {
                this.setState({ error });
            }
        )
    }

    render(){
        const { subbanner } = this.state;

        return (
            <>
            <section className="brandshop-container" >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="product-title common-section-title">
                                <h2>Tiendas de marcas</h2>
                            </div>
                        </div>
                    </div> 
                    <div className="brandwrap">   
                        <div className="row">    
                            <div className="col-lg-3-brand col-md-3-brand col-sm-6 col-xs-6 bs">
                                <Link to={`/brandshop/hisense`} >
                                    <picture>
                                        <img src="/images/marcalogo/marca_logo-11.png" alt="Hisense"/>
                                    </picture>
                                </Link>
                            </div>
                            <div className="col-lg-3-brand col-md-3-brand col-sm-6 col-xs-6 bs">
                                <Link to={`/brandshop/lg`} >
                                    <picture>
                                        <img src="/images/marcalogo/marca_logo-7.png" alt="LG"/>
                                    </picture>
                                </Link>
                            </div>
                            <div className="col-lg-3-brand col-md-3-brand col-sm-6 col-xs-6 bs">
                                <Link to={`/brandshop/samsung`} >
                                    <picture>
                                        <img src="/images/marcalogo/marca_logo-4.png" alt="Samsung"/>
                                    </picture>
                                </Link>
                            </div>
                            <div className="col-lg-3-brand col-md-3-brand col-sm-6 col-xs-6 bs">
                                <Link to={`/brandshop/oster`} >
                                    <picture>
                                        <img src="/images/marcalogo/marca_logo-8.png" alt="Oster"/>
                                    </picture>
                                </Link>
                            </div>
                            <div className="col-lg-3-brand col-md-3-brand col-sm-6 col-xs-6 bs">
                                <Link to={`/brandshop/sankey`} >
                                    <picture>
                                        <img src="/images/marcalogo/marca_logo-6.png" alt="Sankey"/>
                                    </picture>
                                </Link>
                            </div>
                            <div className="col-lg-3-brand col-md-3-brand col-sm-6 col-xs-6 bs">
                                <Link to={`/brandshop/drija`} >
                                    <picture>
                                        <img src="/images/marcalogo/marca_logo-12.png" alt="Drija"/>
                                    </picture>
                                </Link>
                            </div>
                            <div className="col-lg-3-brand col-md-3-brand col-sm-6 col-xs-6 bs">
                                <Link to={`/brandshop/canon`} >
                                    <picture>
                                        <img src="/images/marcalogo/marca_logo-1.png" alt="Canon"/>
                                    </picture>
                                </Link>
                            </div>
                            <div className="col-lg-3-brand col-md-3-brand col-sm-6 col-xs-6 bs">
                                <Link to={`/brandshop/frigidaire`} >
                                    <picture>
                                        <img src="/images/marcalogo/marca_logo-13.png" className="frigidaire-img" alt="Frigidaire"/>
                                    </picture>
                                </Link>
                            </div>
                            <div className="col-lg-3-brand col-md-3-brand col-sm-6 col-xs-6 bs">
                                <Link to={`/brandshop/tcl`} >
                                    <picture>
                                        <img src="/images/marcalogo/tcl.png" alt="TCL"/>
                                    </picture>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {(() => {
                if (subbanner.success){
                    return (
                        <>
                            <section class="sub-banner-container-desktop">
                                <figure><Link to={subbanner.desktop.link}><img src={subbanner.desktop.image} alt={subbanner.desktop.title} /></Link></figure>
                            </section>
                            <section class="sub-banner-container-mobile">
                                <figure><Link to={subbanner.mobile.link}><img src={subbanner.mobile.image} alt={subbanner.mobile.title} /></Link></figure>
                            </section>
                        </>
                    )
                } else {
                    return null;
                }
            })()}
            </>
        )
    }
}